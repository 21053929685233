import axios from "axios";
import {
    Actividad,
    ActualizarCotizacionDTO,
    Archivo,
    CatalogoServicio,
    ConfiguracionSistema,
    Documento,
    DropboxConfig,
    DropboxConfirm,
    EncuestaSatisfaccion,
    EventoCorreoPruebaDTO,
    FiltroCotizacion,
    FiltroServicio,
    FiltroUsuario,
    Grupo,
    Pagina,
    RegistroCotizacion,
    ServiceCatalogPagination,
    ServicePagination,
    Servicio,
    UserPagination,
    Usuario
} from "../types/servicio";

const TIMEOUT = 60000;
const USER_ITEM = 'user';

axios.interceptors.request.use(config => {
    config.timeout = TIMEOUT;
    return config;
});

axios.interceptors.response.use(
    response => response,
    error => {
        if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
            console.log('Request timed out');
        }
        return Promise.reject(error);
    }
);

async function getCsrfToken() {
    try {
        const response = await fetch('/api/csrf')
        if (response?.ok) {
            const payload = await response.json()
            return payload.token
        } else {
            console.log(`HTTP Response Code: ${response?.status}`)
        }
    } catch (err) {
        console.log('There was an error getting the token', err);
    }
}

export function cerrarSesion() {
    sessionStorage.removeItem(USER_ITEM);
}

export async function doLogin(username: string, password: string) {
    return await axios.post("/api/login", {
        username: username,
        password: password
    }, {headers: {'Content-Type': 'application/json'}})
        .then(response => {
            sessionStorage.setItem(USER_ITEM, JSON.stringify(response.data));
            return response.data;
        })
}

export async function doLogout() {
    try {
        const token = await getCsrfToken();
        await axios.post("/api/logout", {}, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': token,
            }
        });
    } catch (err) {
        console.log(err);
    }
    sessionStorage.removeItem(USER_ITEM);
}

export async function getCotizaciones(filtro: FiltroCotizacion) {
    const {data} = await axios.get(`/api/cotizaciones`, {
        params: filtro,
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': await getCsrfToken(),
        }
    }).catch(error => {
        throw error
    });
    return data;
}

export async function getCatalogoServicios(pagination: any): Promise<ServiceCatalogPagination> {
    const {data} = await axios.get(`/api/servicios/catalogo`, {
        params: pagination,
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': await getCsrfToken(),
        }
    });
    return data;
}

export async function recuperarContrasenia(correo: any) {
    const {data} = await axios.post(`/public/api/usuarios/${correo}/recuperar`, {},
        {
            headers: {'Content-Type': 'application/json'},
        }
    );
    return data;
}


export async function getDropboxConfig() {
    const {data} = await axios.get('api/dropbox', {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': await getCsrfToken(),
        }
    })
    return data;
}

export async function setDropboxConfig(dropboxConfig: DropboxConfig) {
    const {data} = await axios.post('api/dropbox', dropboxConfig, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': await getCsrfToken(),
        }
    })
    return data;
}

export async function setDropboxConfigCode(dropboxCode: DropboxConfirm) {
    const {data} = await axios.post('api/dropbox/confirm', dropboxCode, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': await getCsrfToken(),
        }
    })
    return data;
}

export async function getEstadisticasByAnio(year: number, grupoId: string | null) {
    const {data} = await axios.get(`api/servicios/estadisticas?year=${year}${grupoId ? '&grupoId=' + grupoId : ''}`, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': await getCsrfToken(),
        }
    })
    return data;
}

export async function getCotizacionesByMes(year: number, month: number, grupoId: string | null) {
    const {data} = await axios.get(`api/servicios/estadisticas/mes?year=${year}&month=${month + 1}${grupoId ? '&grupoId=' + grupoId : ''}`, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': await getCsrfToken(),
        }
    })
    return data;
}
export async function getEstadisticaMes() {
    const {data} = await axios.get(`/api/servicios/estadisticas/mes/coordinador`, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': await getCsrfToken(),
        }
    })
    return data;
}

export async function reenviarCorreo(id: string) {
    const {data} = await axios.post(`/api/cotizaciones/${id}/email`, {}, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': await getCsrfToken(),
            },
        }
    )
    return data;
}


export async function getServicios(filtro: FiltroServicio): Promise<ServicePagination> {
    const {data} = await axios.get('/api/servicios',
        {
            params: filtro,
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': await getCsrfToken(),
            },
        });
    return data;
}

export async function subirArchivo(servicio: Servicio, documentoId: string, formData: FormData) {
    if (servicio.id) {
        const {data} = await axios.post(`/api/servicios/${servicio.id}/documentos/${documentoId}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                'X-CSRF-TOKEN': await getCsrfToken(),
            },
        });
        return data;
    }
}

export const actualizarServicio = async (servicio: Servicio) => {
    const {data} = await axios.post(`/api/servicios/${servicio.id}`, servicio,
        {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': await getCsrfToken(),
            },
        });
    return data;
}


export async function guardarCotizaciones(registroCotizacion: RegistroCotizacion) {
    const {data} = await axios.post('/api/cotizaciones', registroCotizacion, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': await getCsrfToken(),
        },
    });
    return data;
}

export async function updateCotizacion(actualizarCotizacion: ActualizarCotizacionDTO) {
    const {data} = await axios.post(`/api/cotizaciones/${actualizarCotizacion.cotizacion.id}`, actualizarCotizacion, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': await getCsrfToken(),
        },
    });
    return data;
}


export async function aceptarCotizacion(id: string) {
    const {data} = await axios.post(
        `/api/cotizaciones/${id}/aceptar`, {},
        {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': await getCsrfToken(),
            }
        }
    )
    return data
}

export async function rechazarCotizacion(id: string) {
    const {data} = await axios.post(
        `/api/cotizaciones/${id}/rechazar`, {},
        {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': await getCsrfToken(),
            }
        }
    )
    return data
}


export async function actualizarCotizaciones(id: string, cotizaciones: Servicio[]) {
    const {data} = await axios.post(
        `/public/cotizaciones/${id}`, cotizaciones,
        {
            headers: {
                'Content-Type': 'application/json',
            }
        }
    )
    return data
}

export async function getCotizacionesByGroupUuid(id: string): Promise<Servicio[]> {
    const {data} = await axios.get(
        `/public/cotizaciones/${id}`,
        {
            headers: {
                'Content-Type': 'application/json',
            }
        }
    )
    return data;
}

export async function listGroups(): Promise<Grupo[]> {
    const {data} = await axios.get(`/api/usuarios/grupos`, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': await getCsrfToken(),
        }
    });
    return data;
}

export async function filtrarUsuarios(filter: FiltroUsuario): Promise<UserPagination> {
    const {data} = await axios.get(`/api/usuarios`, {
        params: filter,
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': await getCsrfToken(),
        }
    });
    return data;
}

export async function crearUsuario(usuario: Usuario) {
    const {data} = await axios.post(`/api/usuarios`, usuario, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': await getCsrfToken(),
        }
    });
    return data;
}

export async function verificarCorreo(usuario: Usuario): Promise<Usuario> {
    const {data} = await axios.get(`/api/usuarios/verificar`, {
        params: {correo: usuario.correo},
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': await getCsrfToken(),
        }
    });
    return data;
}

export async function actualizarUsuario(usuario: Usuario) {
    const {data} = await axios.post(`/api/usuarios/${usuario.id}`, usuario, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': await getCsrfToken(),
        }
    });
    return data;
}

export async function eliminarUsuario(id: string) {
    const {data} = await axios.post(`/api/usuarios/${id}/eliminar`, {}, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': await getCsrfToken(),
        }
    });
    return data;
}

export async function listAllCoordinadores() {
    const {data} = await axios.get(`/api/usuarios/coordinadores`, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': await getCsrfToken(),
        }
    });
    return data;
}

export async function listAllCalidad() {
    const {data} = await axios.get('/api/usuarios/calidad', {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': await getCsrfToken(),
        }
    });
    return data;
}

export async function listAllOperativos() {
    const {data} = await axios.get(`/api/usuarios/operativos`, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': await getCsrfToken(),
        }
    });
    return data;
}

export const roleText = (role: string) => {
    if (role === 'ROLE_CLIENT') {
        return 'Cliente';
    }
    if (role === 'ROLE_ADMIN') {
        return 'Administrador';
    }
    if (role === 'ROLE_VENTAS') {
        return 'Ventas';
    }
    if (role === 'ROLE_OPERATOR') {
        return 'Operativo';
    }
    if (role === 'ROLE_MANAGER') {
        return 'Coordinador';
    }
    if (role === 'ROLE_QUALITY_ASSURANCE') {
        return 'Calidad';
    }
    return role;
}


export async function verificarDocumento(servicioId: string, documentoId: string, verificado: boolean) {
    const {data} = await axios.post(`/api/servicios/${servicioId}/documentos/${documentoId}/verificado`,
        {}, {
            params: {verificado: verificado},
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': await getCsrfToken(),
            }
        });
    return data;
}


export async function guardarEncuestaSatisfaccion(servicioId: string, encuestaSatisfaccion: EncuestaSatisfaccion) {
    const {data} = await axios.post(`/public/servicios/${servicioId}/encuestaSatisfaccion`,
        encuestaSatisfaccion,
        {
            headers: {
                'Content-Type': 'application/json',
            }
        });
    return data;
}

export async function guardarStatusDocumentos(servicioId: string, observaciones: string, verificado: boolean) {
    const {data} = await axios.post(`/api/servicios/${servicioId}/documentos/observaciones`,
        {
            observaciones: observaciones,
            verificado: verificado,
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': await getCsrfToken(),
            }
        });
    return data;
}


export async function getConfiguracionCorreo() {
    const {data} = await axios.get(`/api/configuracion/correo`,
        {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': await getCsrfToken(),
            }
        });
    return data;
}

export async function getConfiguracionSistema(): Promise<ConfiguracionSistema> {
    const {data} = await axios.get(`/api/configuracion/sistema`,
        {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': await getCsrfToken(),
            }
        });
    return data;
}

export async function actualizarConfiguracionSistema(configuracion: ConfiguracionSistema) {
    const {data} = await axios.post(`/api/configuracion/sistema`, configuracion,
        {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': await getCsrfToken(),
            }
        });
    return data;
}

export async function actualizarPlantillaCorreo(eventoId: string, formData: FormData) {
    const {data} = await axios.post(`/api/eventos/${eventoId}/correo/plantilla`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            'X-CSRF-TOKEN': await getCsrfToken(),
        },
    });
    return data;
}

export async function actualizarPlantillaWord(id: string, formData: FormData) {
    const {data} = await axios.post(`/api/eventos/${id}/word/plantilla`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            'X-CSRF-TOKEN': await getCsrfToken(),
        },
    });
    return data;
}

export async function buscarClienteByCorreo(correo: string,prefijoFolio:string) {
    const {data} = await axios.get(`/api/servicios/clientes/${correo}/${prefijoFolio}`, {
        headers: {
            "Content-Type": "application/json",
            'X-CSRF-TOKEN': await getCsrfToken(),
        },
    });
    return data;
}


export async function getAllEventos() {
    const {data} = await axios.get(`/api/eventos`, {
        headers: {
            "Content-Type": "application/json",
            'X-CSRF-TOKEN': await getCsrfToken(),
        },
    });
    return data;
}

export async function actualizarEvento(eventoId: string, evento: EventoCorreoPruebaDTO) {
    const {data} = await axios.post(`/api/eventos/${eventoId}`, evento, {
        headers: {
            "Content-Type": "application/json",
            'X-CSRF-TOKEN': await getCsrfToken(),
        },
    });
    return data;
}

export async function generarDocumentoWordPrueba(formData: FormData) {
    const {data} = await axios.post(`/api/eventos/word/generar/prueba`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            'X-CSRF-TOKEN': await getCsrfToken(),
        },
    });
    return data;
}

export async function sendTestEmail(eventoId: string, evento: EventoCorreoPruebaDTO) {
    const {data} = await axios.post(`/api/eventos/${eventoId}/correo/prueba`, evento, {
        headers: {
            "Content-Type": "application/json",
            'X-CSRF-TOKEN': await getCsrfToken(),
        },
    });
    return data;
}

export async function getPaginaNotificaciones(id: string, pagina: Pagina) {
    const {data} = await axios.get(`/api/notificaciones/usuarios/${id}`, {
        params: pagina,
        headers: {
            "Content-Type": "application/json",
            'X-CSRF-TOKEN': await getCsrfToken(),
        },
    });
    return data;
}

export async function readAllNotificaciones(id: string) {
    const {data} = await axios.post(`/api/notificaciones/usuarios/${id}`, {}, {
        headers: {
            "Content-Type": "application/json",
            'X-CSRF-TOKEN': await getCsrfToken(),
        },
    });
    return data;
}

export async function getUltimasNotificaciones(id: string) {
    const {data} = await axios.get(`/api/notificaciones/usuarios/${id}/events/last`, {
        headers: {
            "Content-Type": "application/json",
            'X-CSRF-TOKEN': await getCsrfToken(),
        },
    });
    return data;
}

export async function restablecerContrasenia(id: string) {
    const {data} = await axios.post(`/api/usuarios/${id}/reestablecer`, {}, {
        headers: {
            "Content-Type": "application/json",
            'X-CSRF-TOKEN': await getCsrfToken(),
        },
    })
    return data;
}

export async function cambiarFechaInspeccion(servicioId: string, fechaInspeccion: Date) {
    const {data} = await axios.post(`/api/servicios/${servicioId}/fechaInspeccion`, {
        fechaInspeccion: fechaInspeccion
    }, {
        headers: {
            "Content-Type": "application/json",
            'X-CSRF-TOKEN': await getCsrfToken(),
        },
    })
    return data;
}

export async function cambiarFechaCapacitacion(servicioId: string, fechaCapacitacion: Date) {
    const {data} = await axios.post(`/api/servicios/${servicioId}/fechaCapacitacion`, {
        fechaCapacitacion: fechaCapacitacion
    }, {
        headers: {
            "Content-Type": "application/json",
            'X-CSRF-TOKEN': await getCsrfToken(),
        },
    })
    return data;
}


export async function cambiarPersonalCalidad(servicioId: string, usuarioId: String) {
    const {data} = await axios.post(`/api/servicios/${servicioId}/usuarios/calidad/${usuarioId}`, {}, {
        headers: {
            "Content-Type": "application/json",
            'X-CSRF-TOKEN': await getCsrfToken(),
        },
    })
    return data;
}

export async function cambiarCoordinador(servicioId: string, usuarioId: String) {
    const {data} = await axios.post(`/api/servicios/${servicioId}/usuarios/coordinador/${usuarioId}`, {}, {
        headers: {
            "Content-Type": "application/json",
            'X-CSRF-TOKEN': await getCsrfToken(),
        },
    })
    return data;
}

export async function cambiarOperativo(servicioId: string, usuarioId: String) {
    const {data} = await axios.post(`/api/servicios/${servicioId}/usuarios/operativo/${usuarioId}`, {}, {
        headers: {
            "Content-Type": "application/json",
            'X-CSRF-TOKEN': await getCsrfToken(),
        },
    })
    return data;
}

export async function cambiarInstructor(servicioId: string, instructor: Usuario) {
    const {data} = await axios.post(`/api/servicios/${servicioId}/usuarios/instructor`, instructor, {
        headers: {
            "Content-Type": "application/json",
            'X-CSRF-TOKEN': await getCsrfToken(),
        },
    })
    return data;
}

export async function cambiarObservacionesDocumentacion(servicioId: string, observacionesDocumentacion: string) {
    const {data} = await axios.post(`/api/servicios/${servicioId}/observacionesDocumentacion`, {
        observacionesDocumentacion: (observacionesDocumentacion ? observacionesDocumentacion : null)
    }, {
        headers: {
            "Content-Type": "application/json",
            'X-CSRF-TOKEN': await getCsrfToken(),
        },
    })
    return data;
}


export async function isVinculoValido(id: string) {
    const {data} = await axios.get(
        `/public/api/usuarios/${id}/validar`,
        {
            headers: {
                'Content-Type': 'application/json',
            }
        }
    )
    return data;
}

export async function cambiarContrasenia(usuarioId: string, nuevaContrasenia: string) {
    const {data} = await axios.post(
        `/api/usuarios/${usuarioId}/cambiarContrasenia`, {
            id: usuarioId,
            password: nuevaContrasenia
        },
        {
            headers: {
                'X-CSRF-TOKEN': await getCsrfToken(),
                'Content-Type': 'application/json',
            }
        }
    )
    return data;
}

/*servicios para actividades*/

export async function guardarEvidencias(servicio: Servicio, actividad: Actividad, formData: FormData) {
    if (servicio.id && actividad.id) {
        const {data} = await axios.post(`/api/servicios/${servicio.id}/actividades/${actividad.id}/evidencias`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                'X-CSRF-TOKEN': await getCsrfToken(),
            },
        });
        return data;
    }
}

export async function guardarObservaciones(servicio: Servicio, actividad: Actividad, observaciones: string) {
    if (servicio.id && actividad.id) {
        const {data} = await axios.post(`/api/servicios/${servicio.id}/actividades/${actividad.id}/observaciones`, {
            observaciones: observaciones
        }, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': await getCsrfToken(),
            },
        });
        return data;
    }
}

export async function cerrarActividad(servicio: Servicio, actividad: Actividad) {
    if (servicio.id && actividad.id) {
        const {data} = await axios.post(`/api/servicios/${servicio.id}/actividades/${actividad.id}`, {}, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': await getCsrfToken(),
            },
        });
        return data;
    }
}

export async function rechazarActividad(servicio: Servicio, actividad: Actividad) {
    if (servicio.id && actividad.id) {
        const {data} = await axios.post(`/api/servicios/${servicio.id}/actividades/${actividad.id}/rechazar`, {}, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': await getCsrfToken(),
            },
        });
        return data;
    }
}

export async function cancelarActividad(servicio: Servicio, actividad: Actividad) {
    if (servicio.id && actividad.id) {
        const {data} = await axios.post(`/api/servicios/${servicio.id}/actividades/${actividad.id}/cancelar`, {}, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': await getCsrfToken(),
            },
        });
        return data;
    }
}

export async function eliminarEvidencias(servicio: Servicio, actividad: Actividad, archivos: Archivo[]) {
    if (servicio.id && actividad.id) {
        const {data} = await axios.post(`/api/servicios/${servicio.id}/actividades/${actividad.id}/evidencias/eliminar`, {
            evidencias: archivos
        }, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': await getCsrfToken(),
            },
        });
        return data;
    }
}


export async function actualizarCatalogoPlantilla(catalogoServicio: CatalogoServicio, formData: FormData) {
    const {data} = await axios.post(`/api/catalogo/${catalogoServicio.id}/plantilla`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'X-CSRF-TOKEN': await getCsrfToken(),
        },
    });
    return data;
}

export async function eliminarCatalogoPlantilla(catalogoServicio: CatalogoServicio) {
    const {data} = await axios.post(`/api/catalogo/${catalogoServicio.id}/plantilla/eliminar`, {}, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': await getCsrfToken(),
        },
    });
    return data;
}

export async function actualizarCatalogoServicio(catalogoServicio: CatalogoServicio) {
    const {data} = await axios.post(`/api/catalogo/${catalogoServicio.id}`, catalogoServicio, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': await getCsrfToken(),
        },
    });
    return data;
}

export async function crearCatalogoServicio(catalogoServicio: CatalogoServicio) {
    const {data} = await axios.post(`/api/catalogo`, catalogoServicio, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': await getCsrfToken(),
        },
    });
    return data;
}


export async function eliminarCatalogoServicio(id: string) {
    const {data} = await axios.post(`/api/catalogo/${id}/eliminar`, {}, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': await getCsrfToken(),
        },
    });
    return data;
}

/*servicios de prueba*/

export async function registrarServicioPrueba(servicioPrueba: Servicio) {
    const {data} = await axios.post(`/api/catalogo/cotizacion/prueba`, servicioPrueba, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': await getCsrfToken(),
        },
    });
    return data;
}

export async function uploadTmpFile(formData: FormData) {
    const {data} = await axios.post(`/api/archivos/tmp`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'X-CSRF-TOKEN': await getCsrfToken(),
        },
    });
    return data;
}


export async function actualizarDatosGeneralesServicio(id: string, servicio: Servicio) {
    const {data} = await axios.post(`/api/servicios/${servicio.id}/generales`, servicio, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': await getCsrfToken(),
        },
    });
    return data;
}


export async function getPublicServiceDetails(id: string) {
    const {data} = await axios.get(
        `/public/servicios/${id}`,
        {
            headers: {
                'Content-Type': 'application/json',
            }
        }
    )
    return data;
}

export async function getServiceDetails(id: string) {
    const {data} = await axios.get(
        `/api/servicios/${id}`,
        {
            headers: {
                'Content-Type': 'application/json',
            }
        }
    )
    return data;
}


/*
 elimina los archivos indicados en el arreglo archivos[]
estos archivos deben pertenecer a la carpeta documento y servicio especificado
 */
export async function eliminarArchivos(servicio: Servicio, documento: Documento, archivos: Archivo[]) {
    const {data} = await axios.post(`/api/servicios/${servicio.id}/documentos/${documento.id}/archivos/eliminar`, {
        archivos: archivos
    }, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': await getCsrfToken(),
        },
    });
    return data;
}

export async function eliminarArchivo(archivo: Archivo) {
    if (archivo && archivo.url) {
        const {data} = await axios.post(archivo.url + "/eliminar", {}, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': await getCsrfToken(),
            },
        });
        return data;
    }
}

export async function generarFolioCliente(nombreComercial: string,prefijoFolio:string) {

    const {data} = await axios.get(`api/servicios/clientes/folio/${nombreComercial}/${prefijoFolio}`, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': await getCsrfToken(),
        },
    });
    return data;

}