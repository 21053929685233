import {Button, Form} from "react-bootstrap";
import React from "react";
import {EncuestaSatisfaccion} from "../../types/servicio";
import {formatoFechaTiempo} from "../../utils";

export default function ViewEncuestaSatisfaccion(props: {
    encuestaSatisfaccion: EncuestaSatisfaccion,
    cerrar: () => void
}) {
    return (
        <>
            <Form className="row">
                <Form.Group className="col-md-12">
                    <div>
                        <Form.Label className='fw-bold'>
                            {props.encuestaSatisfaccion.cuestionario[0].pregunta}
                        </Form.Label>
                    </div>
                    <div className='d-flex d-inline-flex' style={{marginLeft: '30px'}}>
                        R: {props.encuestaSatisfaccion.cuestionario[0].respuesta}
                    </div>
                </Form.Group>

                <Form.Group className="col-md-12" style={{marginTop: '10px'}}>
                    <div>
                        <Form.Label className='fw-bold'>
                            {props.encuestaSatisfaccion.cuestionario[1].pregunta}
                        </Form.Label>
                    </div>
                    <div className='d-flex d-inline-flex' style={{marginLeft: '30px'}}>
                        R: {props.encuestaSatisfaccion.cuestionario[1].respuesta}
                    </div>
                </Form.Group>

                <Form.Group className="col-md-12" style={{marginTop: '10px'}}>
                    <div>
                        <Form.Label className='fw-bold'>
                            {props.encuestaSatisfaccion.cuestionario[2].pregunta}
                        </Form.Label>
                    </div>
                    <div className='d-flex d-inline-flex' style={{marginLeft: '30px'}}>
                        R: {props.encuestaSatisfaccion.cuestionario[2].respuesta}
                    </div>
                </Form.Group>

                <Form.Group className="col-md-12" style={{marginTop: '10px'}}>
                    <div>
                        <Form.Label className='fw-bold'>
                            {props.encuestaSatisfaccion.cuestionario[3].pregunta}
                        </Form.Label>
                    </div>
                    <div className='d-flex d-inline-flex' style={{marginLeft: '30px'}}>
                        R: {props.encuestaSatisfaccion.cuestionario[3].respuesta}
                    </div>
                </Form.Group>
                <Form.Group className="col-md-12" style={{marginTop: '10px'}}>
                    <div>
                        <Form.Label
                            className='fw-bold'>
                            {props.encuestaSatisfaccion.cuestionario[4].pregunta}
                        </Form.Label></div>
                    <div className='d-flex d-inline-flex' style={{marginLeft: '30px'}}>
                        R: {props.encuestaSatisfaccion.cuestionario[4].respuesta}
                    </div>
                </Form.Group>
                <Form.Group className="col-md-12" style={{marginTop: '10px'}}>
                    <div><Form.Label
                        className='fw-bold'>{props.encuestaSatisfaccion.cuestionario[5].pregunta}</Form.Label></div>
                    <div className='d-flex d-inline-flex' style={{marginLeft: '30px'}}>
                        R: {props.encuestaSatisfaccion.cuestionario[5].respuesta}
                    </div>
                </Form.Group>
                <Form.Group className="col-md-12" style={{marginTop: '10px'}}>
                    <div><Form.Label
                        className='fw-bold'>{props.encuestaSatisfaccion.cuestionario[6].pregunta}</Form.Label></div>
                    <div className='d-flex d-inline-flex' style={{marginLeft: '30px'}}>
                        R: {props.encuestaSatisfaccion.cuestionario[6].respuesta}
                    </div>
                </Form.Group>
                <Form.Group className="col-md-12" style={{marginTop: '10px'}}>
                    <div><Form.Label
                        className='fw-bold'>{props.encuestaSatisfaccion.cuestionario[7].pregunta}</Form.Label></div>
                    <div className='d-flex d-inline-flex' style={{marginLeft: '30px'}}>
                        R: {props.encuestaSatisfaccion.cuestionario[7].respuesta}
                    </div>
                </Form.Group>
                <Form.Group className="col-md-12" style={{marginTop: '10px'}}>
                    <div><Form.Label
                        className='fw-bold'>{props.encuestaSatisfaccion.cuestionario[8].pregunta}</Form.Label></div>
                    <div className='d-flex d-inline-flex' style={{marginLeft: '30px'}}>
                        R: {props.encuestaSatisfaccion.cuestionario[8].respuesta}
                    </div>
                </Form.Group>
            </Form>

            <div className='d-flex justify-content-start' style={{marginTop: '30px'}}>
                Respondido por: {props.encuestaSatisfaccion.respondidoPor.nombreCompleto}
            </div>
            <div className='d-flex justify-content-start'>
                Respondido el: {formatoFechaTiempo(props.encuestaSatisfaccion.respondido)}
            </div>
            <div className='d-flex justify-content-end'>
                <Button size='sm' style={{width: '150px'}} onClick={props.cerrar}>Aceptar</Button>
            </div>
        </>
    )
}