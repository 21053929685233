import {Container} from "react-bootstrap";
import React from "react";


export default function IndicadorItem(props: { titulo: string, valor: string | number }) {
    return (
        <Container style={{margin: '20px'}}>
            <div className='rounded' style={{color: "#727171"}}>
                <center><label>{props.titulo}</label></center>
            </div>
            <div>
                <center>
                    <label className='fs-1 fw-bold' style={{color: '#8f8888'}}>{props.valor}</label>
                </center>
            </div>
        </Container>
    )
}