import React, {useState} from "react";
import ConfirmacionModal from "./ConfirmacionModal";

export default function DeleteButton(props: {
    confirm?: boolean, onClick: () => void, confirmationMessage?: string | null,
    preConfirm?: () => void, onCancel?: () => void
}) {
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
    const [confirmationMessage, setConfirmationMessage] = useState<string>()
    return (
        <>
            <div style={{marginTop: '9px', marginRight: '5px'}}>
                <label className="bi bi-trash action-button fs-5" style={{cursor: "pointer"}} onClick={() => {
                    if (props.confirm) {
                        if (props.preConfirm) {
                            props.preConfirm();
                        }
                        setConfirmationMessage(props.confirmationMessage ? props.confirmationMessage : 'Esta seguro de eliminar el elemento seleccionado');
                        setShowConfirmation(true);
                    } else {
                        setShowConfirmation(false);
                        setConfirmationMessage('');
                        props.onClick();
                    }
                }}/>
            </div>

            <ConfirmacionModal show={showConfirmation}
                               title="Confirmación"
                               message={[confirmationMessage ?? '']}
                               onAccept={() => {
                                   setShowConfirmation(false);
                                   setConfirmationMessage('');
                                   props.onClick();
                               }}
                               onCancel={() => {
                                   if (props.onCancel) {
                                       props.onCancel();
                                   }
                                   setShowConfirmation(false);
                                   setConfirmationMessage('');
                               }}/>
        </>
    )
}