import {useState} from "react";

export function useMessage() {

    const [isError, setIsError] = useState<boolean>(false);
    const [msg, setMsg] = useState<string>('');
    const [show, setShow] = useState<boolean>(false);

    const inputProps = {
            show: show,
            message: msg,
            error: isError,
            close: () => {
                setShow(false);
                setMsg('');
                setIsError(false);
            },
            showError: (e: string) => {
                setShow(true);
                setMsg(e);
                setIsError(true);
            },
            showMessage:
                (e: string) => {
                    setMsg(e);
                    setShow(true);
                    setIsError(false);
                }
        }
    ;

    return inputProps;
}