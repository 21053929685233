import {Modal} from "react-bootstrap";
import {CatalogoServicio} from "../../types/servicio";
import React from "react";
import FormCatalogoServicio from "./FormCatalogoServicio";

export default function CatalogoServicioModal(props: {
    show: boolean,
    catalogoServicio: CatalogoServicio,
    close: () => void,
    downloadDocCotizacionPrueba?: (catalogoServicio: CatalogoServicio) => void,
    guardarCambios?: (catalogoServicio: CatalogoServicio) => void,
    cambiarPlantilla?: (formData: FormData) => Promise<{ codigo: number, mensaje: string, object: any }>;
}) {

    return (
        <Modal size="lg" className="modal-dialog-centered" show={props.show}>
            <Modal.Header className="modal-header">
                <Modal.Title className="fs-6">
                    Catálogo de servicios / {props.catalogoServicio?.id ? 'Editar servicio' : 'Nuevo servicio'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormCatalogoServicio
                    editarNombre={true}
                    enableTestFile={true}
                    catalogoServicio={props.catalogoServicio}
                    ocultar={() => {
                        props.close()
                    }}
                    cambiarPlantilla={props.cambiarPlantilla}
                    downloadDocCotizacionPrueba={props.downloadDocCotizacionPrueba}
                    guardarCambios={props.guardarCambios}
                />
            </Modal.Body>
        </Modal>
    )
}