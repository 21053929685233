import React from "react";

export default function Advertencia(props: { mensaje: string, showIcon: boolean }) {
    return (
        <div className='rounded'
             style={{
                 background: 'rgb(220,219,219)',
                 padding: '20px',
                 marginBottom: '10px'
             }}>
            <p style={{fontSize: '0.9em'}}>
                {props.showIcon && <span className='fs-6 fi bi-info-circle-fill'/>} {props.mensaje}
            </p>
        </div>
    )
}