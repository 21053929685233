import PageHeader from "../common/PageHeader";
import React, {useEffect, useState} from "react";
import {Accordion, Container} from "react-bootstrap";
import {Evento} from "../../types/servicio";
import {getAllEventos} from "../../services/conem-services";
import {SUCCESS} from "../../utils";
import EventoItem from "./EventoItem";


export default function EventosIndex() {

    const [eventos, setEventos] = useState<Evento[] | null>(null);

    useEffect(() => {
        try {
            getAllEventos().then(data => {
                if (data.codigo === SUCCESS) {
                    setEventos(data.object);
                } else {
                    //showError(data.mensaje);
                }
            });
        } catch (err) {
            //showError("Lo sentimos ha ocurrido un error al intentar cargar la configuración de eventos");
        }
    }, []);



    return (<>
        <Container style={{marginBottom: '20px'}}>
            <PageHeader label="Configuración de correos y notificaciones"/>
            <hr/>
            <Accordion>
                {
                    eventos?.map((evento: Evento, index: number) => {
                        return <Accordion.Item eventKey={`${index}`}>
                            <Accordion.Header>{evento.descripcion}</Accordion.Header>
                            <Accordion.Body style={{backgroundColor: '#FAFAFA'}}>
                                <EventoItem evento={evento}
                                onUpdate={(e:Evento)=>{

                                }}/>
                            </Accordion.Body>
                        </Accordion.Item>
                    })
                }
            </Accordion>
        </Container>
    </>)
}