import {Button, Col, Container, Form, Row} from "react-bootstrap";
import React, {useEffect, useRef, useState} from "react";
import {
    generarFolioCliente,
    listGroups,
    registrarServicioPrueba,
    updateCotizacion,
    uploadTmpFile,
} from "../../services/conem-services";
import CatalogoServicioPanel from "./CatalogoServicioPanel";
import {Archivo, CatalogoServicio, Grupo, Servicio, Subservicio, WorkingDay} from "../../types/servicio";
import ModalMessage from "../common/ModalMessage";
import Loader from "../common/Loader";
import {
    dateToSimpleFormat,
    formatoNumeroFolio,
    isAdmin,
    isCorreoValido,
    isTelefonoValido,
    PREFIJO_FOLIO,
    SUCCESS,
    toDate,
    truncarTexto
} from "../../utils";
import CatalogoServicioModal from "../catalogo-servicio/CatalogoServicioModal";
import cloneDeep from "lodash.clonedeep";
import {useFormInput} from "./useFormInput";
import DatePicker from "react-datepicker";
import DiasSemana from "./DiasSemana";
import FileChooser from "../documento/FileChooser";
import {toInteger} from "lodash";
import DeleteButton from "../common/DeleteButton";

export default function FormEdicionCotizacion(props: {
    cotizacion: Servicio,
    onClose: () => void,
    onUpdate: (cotizacion: Servicio) => void
}) {

    const hiddenFileInput = useRef(null);

    const [contadorNumeroFolio, setContadorNumeroFolio] = useState<number>(0);
    const [folioAsignado, setFolioAsignado] = useState<number>(-1);
    const [catalogoPrefijoFolio, setCatalogoPrefijoFolio] = useState<string | null>(null);

    const [documentoCotizacion, setDocumentoCotizacion] = useState<Archivo | null>(null);


    const [constanciaFiscal, setConstanciaFiscal] = useState<Archivo | null>(null);
    const [vistoBuenoAniosAnteriores, setVistoBuenoAniosAnteriores] = useState<boolean>(false);
    const [vigenciaVistoBueno, setVigenciaVistoBueno] = useState<Date | null>(null);
    const [diasLaborales, setDiasLaborales] = useState<WorkingDay[]>([
        {
            name: 'Domingo', enabled: false
        },
        {
            name: 'Lunes', enabled: true
        },
        {
            name: 'Martes', enabled: true
        },
        {
            name: 'Miércoles', enabled: true
        },
        {
            name: 'Jueves', enabled: true
        },
        {
            name: 'Viernes', enabled: true
        },
        {
            name: 'Sábado', enabled: false
        },
    ]);
    const changeSelectionDay = (index: number, value: boolean) => {
        setDiasLaborales(
            diasLaborales.map((day: WorkingDay, i: number) => {
                if (i === index) {
                    day.enabled = value;
                }
                return day;
            })
        )
    }

    const [loading, setLoading] = useState(false)

    const [catalogoServicio, setCatalogoServicio] = useState<CatalogoServicio>(
        {
            prefijoFolio:props.cotizacion.folio.split('-')[1],
            actividades: props.cotizacion.actividades,
            descripcion: props.cotizacion.descripcion,
            descuento: props.cotizacion.descuento,
            documentos: props.cotizacion.documentos,
            errores: null,
            id: props.cotizacion.id,
            iva: props.cotizacion.iva,
            plantillaDescargable: props.cotizacion.plantillaDocumentoCotizacion,
            precio: props.cotizacion.precio,
            subservicios: props.cotizacion.subservicios,
            subtotal: props.cotizacion.subtotal

        }
    );

    const [showCatalogoServicioPanel, setShowCatalogoServicioPanel] = useState<boolean>(false);

    const [msgBtnCreateServiceProposal, setMsgBtnCreateServiceProposal] = useState("Actualizar cotización");
    const [disableBtnCreateServiceProposal, setDisableBtnCreateServiceProposal] = useState(false);

    const [nivelRiesgo, setNivelRiesgo] = useState('bajo');
    const [tipoCliente, setTipoCliente] = useState('nuevo');

    const [requiereFactura, setRequiereFactura] = useState<boolean>(false);
    const [grupos, setGrupos] = useState<Grupo[] | null>(null);
    const [grupoSeleccionado, setGrupoSeleccionado] = useState<Grupo | null>(null);


    const [cotizacion, setCotizacion] = useState<Servicio>(props.cotizacion);




    useEffect(() => {

        const cotizacion = cloneDeep(props.cotizacion);
        setCotizacion(cotizacion);

        setNivelRiesgo(cotizacion.cliente.detalle.nivelRiesgo);
        setTipoCliente(cotizacion.cliente.detalle.tipoCliente);

        setRequiereFactura(cotizacion.requiereFactura);
        if (cotizacion.constanciaSituacionFiscal) {
            setConstanciaFiscal(cotizacion.constanciaSituacionFiscal);
        }
        if (cotizacion.cuentaVistoBuenoPCAnteriores) {
            setVistoBuenoAniosAnteriores(cotizacion.cuentaVistoBuenoPCAnteriores)
        }
        if (cotizacion.vigenciaVistoBueno) {
            setVigenciaVistoBueno(toDate(cotizacion.vigenciaVistoBueno));
        }

        if (cotizacion.diasLaborales) {
            setDiasLaborales(cotizacion.diasLaborales);
        }

        if (cotizacion.documentoCotizacion){
            setDocumentoCotizacion(cotizacion.documentoCotizacion)
        }

        listGroups().then(data => {
            setGrupos(data);
        }).catch(error => {
            console.log(error)
        })

        setGrupoSeleccionado(cotizacion.grupo);

        setCatalogoServicio({
            prefijoFolio:cotizacion.folio.split('-')[1],
            actividades: cotizacion.actividades,
            descripcion: cotizacion.descripcion,
            descuento: cotizacion.descuento,
            documentos: cotizacion.documentos,
            errores: null,
            id: cotizacion.id,
            iva: cotizacion.iva,
            plantillaDescargable: cotizacion.plantillaDocumentoCotizacion,
            precio: cotizacion.precio,
            subservicios: cotizacion.subservicios,
            subtotal: cotizacion.subtotal,
        })

        setCatalogoPrefijoFolio(cotizacion.folio.split('-')[1])
        generarFolioCliente(cotizacion.cliente.detalle.nombreComercial,cotizacion.folio.split('-')[1]).then(resp => {
            if (resp.codigo === SUCCESS) {
                const numeroFolio = toInteger(resp.object.contador);
                setFolioAsignado(numeroFolio);
                setContadorNumeroFolio(numeroFolio + 1);
                folio.value=resp.object.nombreFolio;
            } else {
                //showError(resp.mensaje);
            }
        }).catch(err => console.log("Error ocurrido al intentar generar el nombreFolio"));

    }, [props.cotizacion]);



    const downloadFile=(archivo:Archivo)=>{
        if(archivo.url){

        }else{
            //if archivo is a local file

        }
    }


    const clickSelectFiles = () => {
        if (hiddenFileInput && hiddenFileInput.current) {
            // @ts-ignore
            hiddenFileInput.current.click();
        }
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files.item(0);
            if (file && (file.size <= 0 || file.size >= 50 * 1024 * 1024)) {
                //showError("El tamaño mínimo de archivo es 1 byte y menor a 50 MB");
                return;
            }

            if (file) {

                setDocumentoCotizacion({
                    dropboxLink: 'undefined',
                    fechaSubido: "",
                    folder: 'undefined',
                    id: 'undefined',
                    seleccionado: false,
                    subidoPor: 'undefined',
                    tamanio: 'undefined',
                    url: "",
                    nombre: file.name
                })


                const formData = new FormData();
                formData.set("file", e.target.files[0]);

                setDocumentoCotizacion(null);
                uploadTmpFile(formData).then(
                    resp => {
                        if (resp.codigo === SUCCESS) {
                            setDocumentoCotizacion(resp.object);
                        } else {
                            //showError(resp.mensaje);
                        }
                    }
                ).catch(err => {
                    //showError("Lo sentimos ocurrió un error al intentar subir los archivos, por favor inténtelo más tarde");
                })
            }
            // @ts-ignore
            e.target.value = null;
        }
    };

    function isFormValid() {
        let valid = true;
        valid = (
            correo.error === null &&
            nombreContacto.error === null &&
            telefonoContacto.error === null &&
            direccionExacta.error === null &&
            giroComercial.error === null &&
            metrosConstruccion.error === null &&
            sustanciasQuimicosPeligrosos.error === null &&
            gasCapacidadAlmacenamiento.error === null &&
            razonSocial.error === null
        )
        return valid;
    }

    const createNewCotizacion = () => {
        const newCotizacion = {...cotizacion}
        newCotizacion.folio = PREFIJO_FOLIO+'-'+catalogoPrefijoFolio+'-'+folio.value+ '-'+formatoNumeroFolio(contadorNumeroFolio);
        newCotizacion.cliente.correo = correo.value;
        newCotizacion.cliente.nombreCompleto = nombreContacto.value;
        newCotizacion.cliente.telefono = telefonoContacto.value;
        newCotizacion.cliente.detalle.nombreComercial = nombreComercial.value;
        newCotizacion.cliente.detalle.direccion = direccionExacta.value;
        newCotizacion.cliente.detalle.giro = giroComercial.value;
        newCotizacion.cliente.detalle.metrosConstruccion = metrosConstruccion.value;
        newCotizacion.cliente.detalle.metrosTerreno = metrosTerreno.value;
        newCotizacion.cliente.detalle.numeroEmpleados = numeroEmpleados.value;
        newCotizacion.cliente.detalle.sustanciasQuimicasPeligrosas = sustanciasQuimicosPeligrosos.value;
        newCotizacion.cliente.detalle.gasCapacidadAlmacenamiento = gasCapacidadAlmacenamiento.value;
        newCotizacion.cliente.detalle.razonSocial = razonSocial.value;
        newCotizacion.cliente.detalle.domicilioFiscal = domicilioFiscal.value;
        newCotizacion.cliente.detalle.rfc = rfc.value;
        newCotizacion.cliente.detalle.nivelRiesgo = nivelRiesgo;
        newCotizacion.cliente.detalle.tipoCliente = tipoCliente;
        newCotizacion.requiereFactura = requiereFactura;
        newCotizacion.constanciaSituacionFiscal = requiereFactura?constanciaFiscal:null;
        newCotizacion.cuentaVistoBuenoPCAnteriores = vistoBuenoAniosAnteriores;
        newCotizacion.vigenciaVistoBueno = vigenciaVistoBueno?dateToSimpleFormat(vigenciaVistoBueno):null;
        newCotizacion.diasLaborales = diasLaborales;
        newCotizacion.representanteLegalInmueble = representanteLegalInmueble.value;
        newCotizacion.nombrePersonalRecibeInspector = nombrePersonalRecibiraInspector.value;
        newCotizacion.nivelesConstruccion = nivelesConstruccion.value;
        newCotizacion.horarioLaboral = horarioLaboral.value;
        newCotizacion.grupo = grupoSeleccionado;
        return newCotizacion;
    }

    const actualizarCotizacion = async () => {

        if (isFormValid() && catalogoServicio && catalogoPrefijoFolio) {
            if (!documentoCotizacion){
                mostrarMensajeError("Se requiere especificar el documento de cotización");
                return;
            }
            const newCotizacion = createNewCotizacion();
            const documentos = cloneDeep(catalogoServicio.documentos);
            const subservicios = cloneDeep(catalogoServicio.subservicios);
            const sumaDescuentos = subservicios.reduce((accumulator: number, item: Subservicio) => {
                return accumulator + (item.precio * (item.porcentajeDescuento / 100));
            }, 0);
            const sumaPrecios = subservicios.reduce((accumulator: number, item: Subservicio) => {
                return accumulator + item.precio;
            }, 0);
            const iva = (sumaPrecios - sumaDescuentos) * 0.16;
            newCotizacion.descripcion = catalogoServicio.descripcion;
            newCotizacion.precio = sumaPrecios;
            newCotizacion.descuento = sumaDescuentos;
            newCotizacion.iva = iva;
            newCotizacion.subtotal = (sumaPrecios - sumaDescuentos);
            newCotizacion.total = (sumaPrecios - sumaDescuentos) + iva;
            newCotizacion.subservicios = subservicios;
            newCotizacion.documentos = documentos;
            newCotizacion.documentoCotizacion = documentoCotizacion;

            setMsgBtnCreateServiceProposal("Guardando...");
            setLoading(true);
            setDisableBtnCreateServiceProposal(true);
            updateCotizacion({
                nombreFolio:folio.value,
                folioAsignado:folioAsignado,
                nombreComercial:nombreComercial.value,
                prefijoFolio:catalogoPrefijoFolio,
                cotizacion:newCotizacion,
            }).then((data) => {
                if (data.codigo === SUCCESS) {
                    props.onUpdate(data.object);
                    props.onClose();
                } else {
                    mostrarMensajeError(data.mensaje);
                }
            }).catch(err => {
                mostrarMensajeError('Lo sentimos ha ocurrido un error al intentar guardar la cotización');
            }).finally(() => {
                setMsgBtnCreateServiceProposal("Actualizar cotización");
                setLoading(false);
                setDisableBtnCreateServiceProposal(false);
            });
        } else {
            mostrarMensajeError('Por favor verifique y proporcione los campos requeridos');
        }
    }

    const [message, setMessage] = useState<string>('');
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const mostrarMensajeError = (message: string) => {
        setError(true);
        setMessage(message);
        setShowMessage(true);
    }

    const correo = useFormInput(
        props.cotizacion.cliente.correo,
        100,
        'El correo de contacto es requerido',
        false,
        true,
        false,
        isCorreoValido,
    );

    const nombreContacto = useFormInput(
        props.cotizacion.cliente.nombreCompleto,
        200,
        'El nombre del contacto es requerido',
        false,
        true,
        false
    );
    const telefonoContacto = useFormInput(
        props.cotizacion.cliente.telefono,
        10,
        'El número de teléfono de contacto es requerido',
        false,
        true,
        false,
        isTelefonoValido,
    );
    const verificarFolio = (value: string) => {
        if (!value.match(/[A-Z]+/)) {
            return false;
        }
        return true;
    }
    const folio = useFormInput(
        cotizacion.nombreFolio,
        100,
        'El número de folio es requerido y debe ser válido',
        false,
        true,
        false,
        verificarFolio,
    );
    const nombreComercial = useFormInput(
        props.cotizacion.cliente.detalle.nombreComercial,
        200,
        'El nombre comercial es un campo requerido',
        false,
        true,
        false,
        null,
        (value:string)=>{
            generarFolioCliente(value,cotizacion.folio.split('-')[1]).then(resp => {
                if (resp.codigo === SUCCESS) {
                    const numeroFolio = toInteger(resp.object.contador);
                    setFolioAsignado(numeroFolio);
                    setContadorNumeroFolio(numeroFolio + 1);
                    let nombreFolio = truncarTexto(value.toUpperCase().replace(/[\s|\.|,|_|-]/g, ''), 20, false);
                    if (resp.object.nombreFolio) {
                        nombreFolio = resp.object.nombreFolio;
                    }
                    folio.setValue(nombreFolio);
                } else {

                }
            }).catch(err => console.log("Error ocurrido al intentar generar el nombreFolio"));
        }
    );
    const direccionExacta = useFormInput(
        props.cotizacion.cliente.detalle.direccion,
        400,
        'La dirección exacta es un campo requerido',
        false,
        true,
        false,
    );
    const giroComercial = useFormInput(
        props.cotizacion.cliente.detalle.giro,
        400,
        'El campo Giro Comercial es requerido',
        false,
        true,
        false,
    );
    const metrosConstruccion = useFormInput(
        props.cotizacion.cliente.detalle.metrosConstruccion,
        100,
        'El campo Metros de Construcción es requerido',
        false,
        true,
        false,
    );
    const metrosTerreno = useFormInput(
        props.cotizacion.cliente.detalle.metrosTerreno,
        100,
        null,
        true,
        false,
        false,
    );
    const numeroEmpleados = useFormInput(
        props.cotizacion.cliente.detalle.numeroEmpleados,
        100,
        null,
        true,
        false,
        false,
    );
    const sustanciasQuimicosPeligrosos = useFormInput(
        props.cotizacion.cliente.detalle.sustanciasQuimicasPeligrosas,
        300,
        'Indique NA en caso de no aplicar',
        false,
        true,
        false,
    );
    const gasCapacidadAlmacenamiento = useFormInput(
        props.cotizacion.cliente.detalle.gasCapacidadAlmacenamiento,
        300,
        'Indique NA en caso de no aplicar',
        false,
        true,
        false,
    );
    const razonSocial = useFormInput(
        props.cotizacion.cliente.detalle.razonSocial,
        100,
        'La Razón Social es un campo requerido',
        false,
        true,
        false,
    );
    const domicilioFiscal = useFormInput(
        props.cotizacion.cliente.detalle.domicilioFiscal,
        400,
        null,
        true,
        false,
        false,
    );
    const rfc = useFormInput(
        props.cotizacion.cliente.detalle.rfc,
        30,
        null,
        true,
        false,
        false,
    );

    const representanteLegalInmueble = useFormInput(
        props.cotizacion.representanteLegalInmueble,
        200,
        null,
        true,
        false,
        false,
    );
    const nombrePersonalRecibiraInspector = useFormInput(
        props.cotizacion.nombrePersonalRecibeInspector,
        200,
        null,
        true,
        false,
        false,
    );
    const nivelesConstruccion = useFormInput(
        props.cotizacion.nivelesConstruccion,
        50,
        null,
        true,
        false,
        false,
    );
    const horarioLaboral = useFormInput(
        props.cotizacion.horarioLaboral,
        50,
        null,
        true,
        false,
        false,
    );

    const downloadPreviewDoc = (catalogoServicio: CatalogoServicio) => {
        if (isFormValid()) {
            // @ts-ignore
            let servicioPrueba: Servicio = {
                requiereFactura: requiereFactura,
                grupo: grupoSeleccionado,
                plantillaDocumentoCotizacion: catalogoServicio.plantillaDescargable,
                folio: PREFIJO_FOLIO+'-'+folio.value + '-' + formatoNumeroFolio(contadorNumeroFolio),
                cliente: {
                    nombreCompleto: nombreContacto.value,
                    correo: correo.value,
                    telefono: telefonoContacto.value,
                    detalle: {
                        nombreComercial: nombreComercial.value,
                        giro: giroComercial.value,
                        metrosConstruccion: metrosConstruccion.value,
                        metrosTerreno: metrosTerreno.value,
                        numeroEmpleados: numeroEmpleados.value,
                        direccion: direccionExacta.value,
                        sustanciasQuimicasPeligrosas: sustanciasQuimicosPeligrosos.value,
                        gasCapacidadAlmacenamiento: gasCapacidadAlmacenamiento.value,
                        nivelRiesgo: nivelRiesgo,
                        tipoCliente: tipoCliente,
                        razonSocial: razonSocial.value,
                        domicilioFiscal: domicilioFiscal.value,
                        rfc: rfc.value,
                    }
                },
                total: catalogoServicio.iva + catalogoServicio.subtotal,
                ...catalogoServicio
            }
            registrarServicioPrueba(servicioPrueba).then(resp => {
                if (resp.codigo === SUCCESS) {

                    fetch(`api/catalogo/cotizacion/prueba/${resp.object.id}`)
                        .then(response => {
                            if (catalogoServicio.plantillaDescargable) {
                                response.blob().then(blob => {
                                    let url = window.URL.createObjectURL(blob);
                                    let a = document.createElement('a');
                                    a.href = url;
                                    a.download = `${catalogoServicio.plantillaDescargable?.nombre}`;
                                    a.click();
                                });
                            }
                        }).catch(err => console.log(err));
                }

            }).catch(err => console.log(err))
        }else{
            mostrarMensajeError("Favor de verificar y especificar los parametros requeridos");
        }
    }

    return (
        <Container style={{marginTop: '20px', marginBottom: '20px'}}>
            <Loader show={loading}></Loader>

            <Container>

                <input id="file" type="file" ref={hiddenFileInput} accept='application/pdf'
                       style={{display: 'none'}}
                       onChange={handleFileChange}/>

                <Form className="row g-6" id="formGeneral">
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Correo del Contacto</Form.Label>
                        <Form.Text className="text-muted"> (Requerido)</Form.Text>
                        <Form.Control type="email" className="formFont" {...correo}/>
                        <Form.Control.Feedback type="invalid">{correo.error}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Nombre del Contacto</Form.Label>
                        <Form.Text className="text-muted"> (Requerido)</Form.Text>
                        <Form.Control {...nombreContacto}/>
                        <Form.Control.Feedback type="invalid">{nombreContacto.error}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Teléfono del Contacto</Form.Label>
                        <Form.Text className="text-muted"> (Requerido)</Form.Text>
                        <Form.Control type="text" className="formFont" {...telefonoContacto}/>
                        <Form.Control.Feedback
                            type="invalid">{telefonoContacto.error}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Nombre Comercial</Form.Label>
                        <Form.Text className="text-muted"> (Requerido)</Form.Text>
                        <Form.Control type="text" className="formFont" {...nombreComercial}></Form.Control>
                        <Form.Control.Feedback
                            type="invalid">{nombreComercial.error}</Form.Control.Feedback>

                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Folio</Form.Label>
                        <Form.Text className="text-muted"> (Requerido)</Form.Text>

                        <div>
                            <Row>
                                <Col sm='4'>
                                    <Form.Control type='text' disabled={true} value={PREFIJO_FOLIO+'-'+catalogoPrefijoFolio}/>
                                </Col>
                                <Col>
                                    <Form.Control type="text" className="formFont" {...folio}/>
                                </Col>
                                <Col sm='4'>

                                    <Form.Control type='text' disabled={true}
                                                  value={formatoNumeroFolio(contadorNumeroFolio)}/>
                                    <span className='text-muted' style={{fontSize: '0.7rem'}}><span className='bi bi-info-circle'/> Folio de edición</span>
                                </Col>
                            </Row>


                        </div>
                        <Form.Control.Feedback
                            type="invalid">{folio.error}</Form.Control.Feedback>
                    </Form.Group>


                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Dirección Exacta del Inmueble</Form.Label>
                        <Form.Text className="text-muted"> (Requerido)</Form.Text>
                        <Form.Control type="text" className="formFont" {...direccionExacta}/>
                        <Form.Control.Feedback
                            type="invalid">{direccionExacta.error}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Giro Comercial</Form.Label>
                        <Form.Control type="text" className="formFont" {...giroComercial}/>
                        <Form.Control.Feedback
                            type="invalid">{giroComercial.error}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Metros cuadrados de Construcción</Form.Label>
                        <Form.Text className="text-muted"> (Requerido)</Form.Text>
                        <Form.Control type="text" className="formFont" {...metrosConstruccion}/>
                        <Form.Control.Feedback
                            type="invalid">{metrosConstruccion.error}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Metros cuadrados de Terreno</Form.Label>
                        <Form.Control type="text" className="formFont" {...metrosTerreno}/>
                        <Form.Control.Feedback
                            type="invalid">{metrosTerreno.error}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>No. de empleados</Form.Label>
                        <Form.Control type="text" className="formFont" {...numeroEmpleados}/>
                        <Form.Control.Feedback
                            type="invalid">{numeroEmpleados.error}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Sustancias o Químicos Peligrosos</Form.Label>
                        <Form.Text className="text-muted"> (Requerido)</Form.Text>
                        <Form.Control type="text" className="formFont" {...sustanciasQuimicosPeligrosos}/>
                        <Form.Control.Feedback
                            type="invalid">{sustanciasQuimicosPeligrosos.error}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Gas, capacidad de almacenamiento</Form.Label>
                        <Form.Text className="text-muted"> (Requerido)</Form.Text>
                        <Form.Control type="text" className="formFont" {...gasCapacidadAlmacenamiento}/>
                        <Form.Control.Feedback
                            type="invalid">{gasCapacidadAlmacenamiento.error}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Razón Social</Form.Label>
                        <Form.Text className="text-muted"> (Se requiere el nombre de la empresa ante
                            el
                            SAT)</Form.Text>
                        <Form.Control type="text" className="formFont" {...razonSocial}/>
                        <Form.Control.Feedback
                            type="invalid">{razonSocial.error}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Domicilio Fiscal</Form.Label>
                        <Form.Control type="text" className="formFont" {...domicilioFiscal}/>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>RFC (Registro Federal de Contribuyente)</Form.Label>
                        <Form.Control type="text" className="formFont" {...rfc}/>
                    </Form.Group>
                    <Form.Group></Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral">
                        <Form.Label>Nivel de riesgo</Form.Label>
                        <div className="mb-3">
                            <Form.Check
                                checked={nivelRiesgo === 'bajo'}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setNivelRiesgo('bajo')
                                    }
                                }} inline label="Bajo" type='radio'
                            />
                            <Form.Check
                                checked={nivelRiesgo === 'medio'}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setNivelRiesgo('medio')
                                    }
                                }} inline label="Medio" type='radio'
                            />
                            <Form.Check
                                checked={nivelRiesgo === 'alto'}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setNivelRiesgo('alto')
                                    }
                                }} inline label="Alto" type='radio'
                            />
                        </div>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral">
                        <Form.Label>Tipo de cliente</Form.Label>
                        <div className="mb-3">
                            <Form.Check
                                checked={tipoCliente === 'nuevo'}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setTipoCliente('nuevo')
                                    }
                                }} inline label="Nuevo" type='radio'
                            />
                            <Form.Check
                                checked={tipoCliente === 'renovacion'}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setTipoCliente('renovacion')
                                    }
                                }} inline label="Renovación" type='radio'
                            />
                        </div>
                    </Form.Group>
                    <Form className="row g-6" id="formDatosGeneralesOperativos">
                        <Form.Group className="col-md-6" controlId="formDatosGenerales"
                                    style={{marginBottom: "20px"}}>
                            <Form.Label>Representante Legal del Inmueble</Form.Label>
                            <Form.Control type="text" className="formFont" {...representanteLegalInmueble}/>
                        </Form.Group>
                        <Form.Group className="col-md-6" controlId="formDatosGenerales"
                                    style={{marginBottom: "20px"}}>
                            <Form.Label>Nombre del personal que recibirá al inspector</Form.Label>
                            <Form.Control type="text" className="formFont" {...nombrePersonalRecibiraInspector}/>
                        </Form.Group>
                        <Form.Group className="col-md-6" controlId="formDatosGenerales"
                                    style={{marginBottom: "20px"}}>
                            <Form.Label>Niveles de construcción</Form.Label>
                            <Form.Control type="text" className="formFont" {...nivelesConstruccion}/>
                        </Form.Group>
                        <Form.Group className="col-md-6" controlId="formDatosGenerales"
                                    style={{marginBottom: "20px"}}>
                            <Form.Label>Horario laboral</Form.Label>
                            <Form.Control type="text" className="formFont" {...horarioLaboral}/>
                        </Form.Group>


                        <Form.Group className="col-md-6" controlId="formDatosGenerales"
                                    style={{marginBottom: "20px"}}>
                            <Form.Label>Días laborales</Form.Label>
                            <div>
                                <DiasSemana workingDays={diasLaborales} disabled={false}
                                            change={changeSelectionDay}/>
                            </div>
                        </Form.Group>

                        <Form.Group className="col-md-6" controlId="formDatosGenerales"
                                    style={{marginBottom: "20px"}}>
                            <Form.Label>¿Cuentan con visto bueno de protección civil de años
                                anteriores?</Form.Label>
                            <div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox"
                                           id='requiereFactura'
                                           checked={vistoBuenoAniosAnteriores}
                                           onChange={(e) => setVistoBuenoAniosAnteriores(e.target.checked)}/>
                                    <label className="form-check-label" htmlFor="requiereFactura">
                                        Sí
                                    </label>
                                </div>
                                {
                                    vistoBuenoAniosAnteriores &&
                                    <div>
                                        Vigencia:
                                        <DatePicker isClearable locale="es"
                                                    dateFormat='dd/MM/yyyy'
                                                    selected={vigenciaVistoBueno}
                                                    onChange={(date, event) => {
                                                        setVigenciaVistoBueno(date);
                                                    }}/>
                                    </div>
                                }
                            </div>

                        </Form.Group>
                    </Form>
                </Form>

                <hr/>
                <div style={{marginTop: "10px"}}>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox"
                               id='requiereFactura'
                               checked={requiereFactura}
                               onChange={(e) => setRequiereFactura(e.target.checked)}/>
                        <label className="form-check-label" htmlFor="requiereFactura">
                            ¿Requiere factura?
                        </label>
                        {
                            requiereFactura && <FileChooser archivo={constanciaFiscal} disabled={false}
                                                            buttonUploadLabel={`${constanciaFiscal === null ? 'Agregar' : 'Actualizar'} constancia de situación fiscal`}
                                                            updatedFile={(e: Archivo) => setConstanciaFiscal(e)}/>
                        }
                    </div>
                </div>

                {
                    isAdmin() &&
                    <><Form.Label>Grupo de atención al que se envía esta cotización</Form.Label>
                        <div className="mb-3">
                            {
                                grupos?.map((g: Grupo) => {
                                    return <Form.Check
                                        checked={grupoSeleccionado?.id === g.id}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setGrupoSeleccionado(g);
                                            }
                                        }} inline label={g.nombre} type='radio'
                                    />
                                })
                            }
                        </div>
                    </>

                }


                {
                    cotizacion &&
                    <Container className="d-flex flex-row justify-content-center"
                               style={{marginTop: "30px",marginBottom: '30px'}}>
                        <CatalogoServicioPanel catalogoServicio={catalogoServicio}
                                               seleccionado={true}
                                               seleccionar={(e: CatalogoServicio) => {
                                               }}
                                               editar={(e: CatalogoServicio) => {
                                                   setShowCatalogoServicioPanel(true);
                                               }}
                                               downloadPreview={() => downloadPreviewDoc(catalogoServicio)}
                        />
                    </Container>
                }


                {
                    documentoCotizacion === null &&
                    <div className='rounded d-flex align-items-center justify-content-center'
                         style={{
                             border: '1px dashed gray',
                             height: '80px',
                             backgroundColor: '#fafafa'
                         }}>
                        <label className='fw-bold fs-5 folderName' onClick={clickSelectFiles}
                               style={{color: 'gray'}}>Agregar documento de cotización</label>
                    </div>
                }
                {
                    documentoCotizacion &&
                    <div className='rounded d-flex align-items-center d-inline-flex'
                         style={{
                             width: '100%',
                             border: '1px solid gray',
                             height: '80px',
                             backgroundColor: '#fafafa'
                         }}>

                        <div style={{marginLeft: '30px', width: '93%'}}>
                            <span style={{color: '#303030'}} className='fs-3 bi bi-file-earmark'/>
                            <span className='folderName fw-bold fs-5'
                                  style={{color: 'gray'}}>{documentoCotizacion.nombre}</span>
                        </div>
                        <div className='d-flex justify-content-end'>
                            <DeleteButton onClick={() => {
                                setDocumentoCotizacion(null);
                            }}/>
                        </div>
                    </div>
                }


            </Container>
            <Container className="d-flex flex-row justify-content-center"
                       style={{marginTop: "30px"}}>
                <Button style={{borderRadius: "8px", padding: "10px", width: "260px"}}
                        variant="danger"
                        size='sm'
                        disabled={disableBtnCreateServiceProposal}
                        onClick={props.onClose}>
                    Cancelar
                </Button>
                <Button style={{borderRadius: "8px", padding: "10px", width: "260px"}}
                        variant="dark"
                        size='sm'
                        disabled={disableBtnCreateServiceProposal}
                        onClick={actualizarCotizacion}>
                    {msgBtnCreateServiceProposal}
                </Button>
            </Container>

            <ModalMessage show={showMessage} close={() => setShowMessage(false)} error={error} message={message}/>

            {
                catalogoServicio &&
                <CatalogoServicioModal show={showCatalogoServicioPanel}
                                       catalogoServicio={catalogoServicio}
                                       close={() => {
                                           setShowCatalogoServicioPanel(false)
                                       }}
                                       downloadDocCotizacionPrueba={(tempCatalogoServicio: CatalogoServicio) => {

                                           if (isFormValid() && tempCatalogoServicio) {

                                               const servicioPrueba = createNewCotizacion();
                                               const documentos = cloneDeep(tempCatalogoServicio.documentos);
                                               const subservicios = cloneDeep(tempCatalogoServicio.subservicios);
                                               const sumaDescuentos = subservicios.reduce((accumulator: number, item: Subservicio) => {
                                                   return accumulator + (item.precio * (item.porcentajeDescuento / 100));
                                               }, 0);
                                               const sumaPrecios = subservicios.reduce((accumulator: number, item: Subservicio) => {
                                                   return accumulator + item.precio;
                                               }, 0);
                                               const iva = (sumaPrecios - sumaDescuentos) * 0.16;
                                               servicioPrueba.descripcion = tempCatalogoServicio.descripcion;
                                               servicioPrueba.precio = sumaPrecios;
                                               servicioPrueba.descuento = sumaDescuentos;
                                               servicioPrueba.iva = iva;
                                               servicioPrueba.subtotal = (sumaPrecios - sumaDescuentos);
                                               servicioPrueba.total = (sumaPrecios - sumaDescuentos) + iva;
                                               servicioPrueba.subservicios = subservicios;
                                               servicioPrueba.documentos = documentos;
                                               servicioPrueba.plantillaDocumentoCotizacion = tempCatalogoServicio.plantillaDescargable;
                                               registrarServicioPrueba(servicioPrueba).then(resp => {
                                                   if (resp.codigo === SUCCESS) {
                                                       fetch(`api/catalogo/cotizacion/prueba/${resp.object.id}`)
                                                           .then(response => {
                                                               if (tempCatalogoServicio.plantillaDescargable) {
                                                                   response.blob().then(blob => {
                                                                       let url = window.URL.createObjectURL(blob);
                                                                       let a = document.createElement('a');
                                                                       a.href = url;
                                                                       a.download = `${tempCatalogoServicio.plantillaDescargable?.nombre}`;
                                                                       a.click();
                                                                   });
                                                               }
                                                           });
                                                   } else {
                                                       mostrarMensajeError(resp.mensaje)
                                                   }
                                               }).catch(
                                                   err => mostrarMensajeError("Lo sentimos ocurrió un error al intentar descargar el documento de prueba")
                                               );
                                           }
                                       }}
                                       cambiarPlantilla={uploadTmpFile}
                                       guardarCambios={(e: CatalogoServicio) => {
                                           const subservicios = cloneDeep(e.subservicios);
                                           const sumaDescuentos = subservicios.reduce((accumulator: number, item: Subservicio) => {
                                               return accumulator + (item.precio * (item.porcentajeDescuento / 100));
                                           }, 0);
                                           const sumaPrecios = subservicios.reduce((accumulator: number, item: Subservicio) => {
                                               return accumulator + item.precio;
                                           }, 0);
                                           const iva = (sumaPrecios - sumaDescuentos) * 0.16;

                                           const newCatalogoServicio = cloneDeep(e);
                                           newCatalogoServicio.iva = iva;
                                           newCatalogoServicio.subtotal = sumaPrecios;
                                           newCatalogoServicio.descuento = sumaDescuentos;
                                           setCatalogoServicio(newCatalogoServicio);
                                           setShowCatalogoServicioPanel(false);
                                       }}/>
            }
        </Container>
    )
}