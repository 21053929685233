import {Alert, Button} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {recuperarContrasenia} from "../../services/conem-services";
import ReCAPTCHA from "react-google-recaptcha"
import {SUCCESS} from "../../utils";

export default function FormRecuperarContrasenia(props: { show: boolean, onCancel: () => void }) {

    const [correo, setCorreo] = useState("");
    const [error, setError] = useState(false)
    const [correoInvalido, setCorreoInvalido] = useState(false)

    const [correoEnviado, setCorreoEnviado] = useState(false)
    const [enviando, setEnviando] = useState(false)

    useEffect(() => {
        setCorreoEnviado(false)
        setEnviando(false)
        setError(false)
        setCorreo("")
        setCorreoInvalido(false)
    }, [props.show]);

    const [reCaptchaToken, setReCaptchaToken] = useState('');
    const handleRecaptcha = (value : any) => {
        setReCaptchaToken(value);
    }

    async function submit(event: any) {
        event.preventDefault();
        setEnviando(true);
        try {
            const resp = await recuperarContrasenia(correo);
            if (resp.codigo === SUCCESS) {
                setCorreoEnviado(true);
            } else {
                setError(true);
            }
        } catch (err) {
            setError(true);
        } finally {
            setEnviando(false)
        }
    }

    return (
        <>
            {
                props.show &&
                <>
                    <h4>Recuperar contraseña</h4>
                    <hr/>
                    <form id="nuevaContrasena" onSubmit={submit}>
                        <div className="form-outline mb-lg-auto">
                            <label className="form-label" htmlFor="username">
                                Correo electrónico
                            </label>
                            <input type="email" id="username" required
                                   className={`form-control ${correoInvalido ? "is-invalid" : ""}`}
                                   name="username" value={correo}
                                   onChange={(e) => setCorreo(e.target.value)}/>
                        </div>
                        <div style={{marginTop: "10px"}}>
                            <Alert key="success" variant="success" show={correoEnviado}>
                                Hemos enviado un correo a su bandeja con las instrucciones para recuperar la contraseña
                            </Alert>
                            <Alert key="error" variant="danger" show={error}>
                                Lo sentimos ha ocurrido un error al enviar el correo por favor inténtelo más tarde
                            </Alert>
                        </div>
                        <div className='d-flex justify-content-end'>
                            <ReCAPTCHA sitekey={'6LfKyYgpAAAAAGnAbABsJSxqI0JrisvTXrWwXdM0'} onChange={handleRecaptcha}/>
                        </div>

                        <div className="row mb-10" style={{marginTop: "25px"}}>
                            <div className="text-center">
                                <Button size='sm' variant={correoEnviado ? 'primary' : 'danger'} type="submit"
                                        style={{width: "10rem"}}
                                        disabled={enviando}
                                        onClick={props.onCancel}>
                                    {
                                        correoEnviado ? <>Ir a login</> : <>Cancelar</>
                                    }
                                </Button>
                                <Button size='sm' variant='dark' type="submit" style={{width: "10rem"}}
                                        disabled={enviando || correoEnviado || !reCaptchaToken || !correo}>
                                    {(enviando &&
                                        <span className="spinner-border spinner-border-sm" role="status"
                                              aria-hidden="true"></span>)}
                                    {
                                        enviando ? <span>Enviando...</span> : <>Recuperar</>
                                    }
                                </Button>
                            </div>
                        </div>
                    </form>
                </>
            }
        </>
    )
}