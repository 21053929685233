import {Alert, Button, Form, InputGroup} from "react-bootstrap";
import React, {useState} from "react";
import {doLogin} from "../../services/conem-services";
import {useNavigate} from "react-router-dom";

export default function FormLogin(props: any) {

    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loginError, setLoginError] = useState(false)
    const [msgButton, setMsgButton] = useState("Entrar")
    const [disableButton, setDisableButton] = useState(false)
    const [invalidUsername, setInvalidUsername] = useState(false)
    const [invalidPassword, setInvalidPassword] = useState(false)
    const [showPass, setShowPass] = useState(false)

    async function submitLoginForm(event: any) {
        event.preventDefault();
        setLoginError(false)
        if (username === '') {
            setInvalidUsername(true);
            return
        } else if (password === '') {
            setInvalidPassword(true);
            return
        }

        setMsgButton("Ingresando...");
        setDisableButton(true);
        try {
            let userSession: any = await doLogin(username, password);
            if (userSession) {
                navigate("/home");
            } else {
                setLoginError(true);
            }
        } catch (err: any) {
            if (err.status === 500)
                alert("Lo sentimos por el momento el servicio no esta disponible")
            setLoginError(true);
        } finally {
            setMsgButton("Entrar");
            setDisableButton(false);
        }
    }

    const clickHandler = () => {
        setShowPass((prev) => !prev);
    };

    return (
        <>
            {
                props.show && <>
                    <h4>Inicio de sesión</h4>
                    <hr/>
                    <form id="loginForm" onSubmit={submitLoginForm}>
                        <div className="form-outline mb-lg-auto">
                            <label className="form-label" htmlFor="username">Correo electrónico</label>
                            <input type="email" id="username" required
                                   className={`form-control ${invalidUsername ? "is-invalid" : ""}`}
                                   name="username" value={username}
                                   onChange={(e) => setUsername(e.target.value)}/>
                        </div>
                        <div className="form-outline mb-lg-auto">
                            <label className="form-label"
                                   htmlFor="password">Contraseña</label>
                            <InputGroup className="mb-3">
                                <input type={!showPass?"password":"text"} id="password" required
                                       className={`form-control ${invalidPassword ? "is-invalid" : ""}`}
                                       value={password}
                                       onChange={(e) => setPassword(e.target.value)}/>

                                <InputGroup.Text>
                                    <i onClick={clickHandler}
                                       className={showPass ? 'bi bi-eye-slash' : 'bi bi-eye'}></i>
                                </InputGroup.Text>
                            </InputGroup>
                        </div>
                        <div style={{marginTop: "10px"}}>
                            <Alert key="danger" variant="danger" show={loginError}>
                                Usuario o contraseña incorrectos
                            </Alert>
                        </div>
                        <div className="row">
                            <div className="col d-flex justify-content-end">
                                <a href="#" onClick={props.recuperarPassword}>Olvidé mi contraseña</a>

                            </div>
                            {
                                /*
                                  <a href="#" onClick={props.crearCuenta}>Crear una cuenta</a>
                                 */
                            }
                        </div>
                        <div className="row mb-10" style={{marginTop: "10px"}}>
                            <div className="text-center">
                                <Button type="submit" variant='dark' size='sm' style={{width: "10rem"}}
                                        disabled={disableButton}>
                                    {(disableButton &&
                                        <span className="spinner-border spinner-border-sm" role="status"
                                              aria-hidden="true"></span>)}
                                    <span>{msgButton}</span>
                                </Button>
                            </div>
                        </div>
                    </form>
                </>
            }
        </>
    )
}