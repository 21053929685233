import {truncarTexto} from "../../utils";
import React from "react";
import {Servicio} from "../../types/servicio";


export default function LabelNombreOperativo(props: {
    servicio: Servicio
}) {
    /*si el operativo no está asignado, pero el coordinador sí, se muestra el nombre del coordinador*/
    return (
        <>
            {
                (props.servicio.operador || props.servicio.coordinador) ?
                    <div style={{marginLeft: '20px'}}>
                        <div>Operativo:</div>
                        <div>
                    <span
                        className='fw-bold'>{props.servicio.operador ? truncarTexto(props.servicio.operador.nombreCompleto, 30) :
                        (props.servicio.coordinador ? truncarTexto(props.servicio.coordinador.nombreCompleto, 30) : '')}</span>
                        </div>
                    </div> :
                    <div style={{marginLeft: '20px'}}>
                        <div>Operativo:</div>
                        <div>
                    <span className='fw-bold'>No disponible</span>
                        </div>
                    </div>
            }
        </>
    )
}