import React, {useEffect, useState} from "react";
import {Button, Form, Spinner} from "react-bootstrap";
import {Actividad, Servicio} from "../../types/servicio";
import {guardarObservaciones} from "../../services/conem-services";
import {
    ACTIVIDAD_STATUS_EN_PROGRESO,
    ACTIVIDAD_STATUS_FINALIZADO,
    getUsuarioSesion,
    isCliente,
    SUCCESS
} from "../../utils";

export default function Observaciones(props: { actividad: Actividad, servicio: Servicio }) {

    const [editando, setEditando] = useState<boolean>(false);
    const [observaciones, setObservaciones] = useState<string>('');
    const [actualizando, setActualizando] = useState<boolean>(false);

    useEffect(() => {
        setEditando(false)
        setActualizando(false);
        if (props.actividad.observaciones)
            setObservaciones(props.actividad.observaciones);
        else setObservaciones('')
    }, []);

    const submit = () => {
        setActualizando(true);
        guardarObservaciones(props.servicio, props.actividad, observaciones).then(resp => {
            if (resp.codigo === SUCCESS) {
                setEditando(false);
            } else {
            }
        }).catch(err => {

        }).finally(() => {
            setActualizando(false);
        });
    }

    return (
        <>
            {
                !editando ? <div>
                        {
                            observaciones ? <>
                                    <label style={{fontSize: '0.9em'}}>
                                        Observaciones:
                                    </label>
                                    <div className='rounded'
                                         style={{
                                             background: 'rgb(245 218 216)',
                                             color:'black',
                                             padding: '15px',
                                             marginBottom: '10px'
                                         }}>
                                        <label style={{fontSize: '0.9em'}}>{observaciones}</label>
                                    </div>
                                    {
                                        (!isCliente() && getUsuarioSesion()) !== null &&
                                        <>
                                            <Button variant="outline-dark" size='sm' className='link-accion'
                                                    disabled={actualizando} hidden={ props.actividad.status === ACTIVIDAD_STATUS_FINALIZADO} onClick={() => {
                                                setEditando(true);
                                            }}>
                                                Editar observaciones
                                            </Button>
                                            <Button variant="outline-dark" size='sm' className='link-accion'
                                                    disabled={actualizando} hidden={ props.actividad.status === ACTIVIDAD_STATUS_FINALIZADO} onClick={() => {
                                                setObservaciones('');
                                                submit();
                                            }}>
                                                Eliminar observaciones
                                            </Button>
                                        </>
                                    }
                                </> :
                                <>
                                    {
                                        (!isCliente() && getUsuarioSesion()!== null && props.actividad.status===ACTIVIDAD_STATUS_EN_PROGRESO)  &&
                                        <Button variant="outline-dark" size='sm' className='link-accion'
                                                disabled={actualizando}  onClick={() => {
                                            setEditando(true);
                                        }}>
                                            Agregar observaciones
                                        </Button>
                                    }

                                </>

                        }
                    </div>
                    :
                    <div>
                        <Form.Group className="mb-3" controlId="observaciones">
                            <Form.Label><label style={{fontSize: '0.9em'}}>Observaciones:</label></Form.Label>
                            <Form.Control as="textarea" style={{fontSize: '0.9em'}} rows={3} value={observaciones}
                                          onChange={(e) => setObservaciones(e.target.value)}/>
                        </Form.Group>
                        <Button variant="outline-danger" style={{width:'150px'}} size='sm' className='link-accion' hidden={ props.actividad.status === ACTIVIDAD_STATUS_FINALIZADO}
                                disabled={actualizando} onClick={()=>{
                                    setEditando(false);
                        }}>
                            Cancelar
                        </Button>
                        <Button variant="outline-dark" size='sm' className='link-accion'
                                disabled={actualizando} onClick={submit} hidden={ props.actividad.status === ACTIVIDAD_STATUS_FINALIZADO}>
                            {
                                !actualizando ? <>Guardar observaciones</> : <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    <span>Guardando observaciones</span>
                                </>
                            }
                        </Button>

                    </div>
            }

        </>

    )
}