import {Button, Col, Container, Form, Row} from "react-bootstrap";
import React, {useCallback, useEffect, useState} from "react";
import {getDropboxConfig, setDropboxConfig, setDropboxConfigCode} from "../../services/conem-services";
import PageHeader from "../common/PageHeader";
import Loader from "../common/Loader";
import ModalMessage from "../common/ModalMessage";


export default function DropboxConfigPage() {

    const loadDropboxConfig = useCallback(async () => {
        return await getDropboxConfig();
    }, [])


    const [currentConfig, setCurrentConfig] = useState<any>({});
    const [appName, setAppName] = useState<string>('');
    const [appKey, setAppKey] = useState<string>('');
    const [appSecret, setAppSecret] = useState<string>('');


    const [authorizationCode, setAuthorizationCode] = useState<string>('');
    const [authorizeUrl, setAuthorizeUrl] = useState<string | null>(null);

    const [connected, setConnected] = useState<boolean>(false);

    const [confirmation, setConfirmation] = useState<boolean>(false);
    const [configuring, setConfiguring] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [errors, setErrors] = useState<any>({});

    const [message, setMessage] = useState<string>('');
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const successMessage = (message: string) => {
        setError(false);
        setMessage(message);
        setShowMessage(true);
    }
    const failMessage = (message: string) => {
        setError(true);
        setMessage(message);
        setShowMessage(true);
    }

    const showConfig = (config: any) => {
        setAppName(config.appName ?? 'No configurado');
        setAppKey(config.appKey ?? 'No configurado');
        setAppSecret(config.appKey ? '**********' : 'No configurado');
        setConnected(config.connected);
    }

    useEffect(() => {
        setLoading(true);
        loadDropboxConfig().then(data => {
            setCurrentConfig(data);
            showConfig(data);
        }).catch(error => {
            failMessage('Ha ocurrido un error al obtener la configuración de Dropbox');
        }).finally(() => setLoading(false));
    }, []);

    return <>

        <Loader show={loading}/>
        <Container>


            <PageHeader label="Cuenta de Dropbox"/>


            <Form className="row g-6" id="formGeneral">
                {
                    (!configuring && !confirmation) &&
                    <Form.Group as={Row} className="mb-3" controlId="appStatus">
                        <Form.Label column sm="1">
                            Status
                        </Form.Label>
                        <Col sm="10">
                            <div style={{marginTop: '7px'}}>
                                {
                                    !connected && <><span className="bi bi-exclamation-circle-fill"
                                                          style={{color: 'red'}}></span> Desconectado</>
                                }
                                {
                                    connected && <><span className="bi bi-check-circle-fill"
                                                         style={{color: 'green'}}></span> Conectado</>
                                }
                            </div>
                        </Col>
                    </Form.Group>
                }

                <div>
                    <Form.Group className="col-md-6" controlId="formGeneral">
                        <Form.Label>
                            App Name:
                        </Form.Label>
                        {configuring && <Form.Text className="text-muted"> (Requerido)</Form.Text>}
                        <Form.Control disabled={!configuring} isInvalid={!!errors.appName} value={appName}
                                      onChange={(e) => setAppName(e.target.value)}/>
                        <Form.Control.Feedback type="invalid">{errors.appName}</Form.Control.Feedback>
                    </Form.Group>
                </div>
                <div>
                    <Form.Group className="col-md-6" controlId="formGeneral">
                        <Form.Label>
                            App Key:
                        </Form.Label>
                        {configuring && <Form.Text className="text-muted"> (Requerido)</Form.Text>}
                        <Form.Control disabled={!configuring} isInvalid={!!errors.appKey} value={appKey}
                                      onChange={(e) => setAppKey(e.target.value)}/>
                        <Form.Control.Feedback
                            type="invalid">{errors.appKey}</Form.Control.Feedback>
                    </Form.Group>
                </div>
                <div>
                    <Form.Group className="col-md-6" controlId="formGeneral">
                        <Form.Label>
                            App Secret:
                        </Form.Label>
                        {configuring && <Form.Text className="text-muted"> (Requerido)</Form.Text>}
                        <Form.Control disabled={!configuring} isInvalid={!!errors.appSecret} type="password"
                                      value={appSecret}
                                      onChange={(e) => setAppSecret(e.target.value)}/>
                        <Form.Control.Feedback
                            type="invalid">{errors.appSecret}</Form.Control.Feedback>
                    </Form.Group>
                </div>

                {
                    configuring && <div style={{marginTop: '20px'}}>
                        Para crear una nueva aplicación en dropbox y obtener los parámetros visite el siguiente <a
                        target="_blank" rel="noreferrer"
                        href='https://www.dropbox.com/developers/apps/create?_tk=pilot_lp&_ad=ctabtn1&_camp=create'>enlace</a>
                    </div>
                }

                <div className={!confirmation ? 'hidden' : ''}>
                    <p>
                        Por favor visite el siguiente enlace <a href={authorizeUrl ? authorizeUrl : ''}
                                                                target="_blank" rel="noreferrer">Obtener código
                        desde Dropbox</a> copie el código de autorización proporcionado por Dropbox y péguelo a
                        continuación
                    </p>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                        <Form.Label column sm="2">
                            Código de autorización
                        </Form.Label>
                        <Col sm="9">
                            <Form.Control type="password" isInvalid={!!errors.authorizationCode}
                                          value={authorizationCode}
                                          onChange={(e) => {
                                              setAuthorizationCode(e.target.value);
                                          }}/>
                            <Form.Control.Feedback
                                type="invalid">{errors.authorizationCode}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                </div>

                <div style={{marginTop: '20px'}}>
                    {
                        (!configuring && !confirmation) &&
                        <Button size='sm' variant='dark' onClick={async () => {
                            setConfiguring(true);
                            setAppName('');
                            setAppKey('');
                            setAppSecret('');
                            setAuthorizationCode('');
                            setAuthorizeUrl(null);
                        }}>Nueva configuración</Button>
                    }
                    {
                        configuring && <>
                            <Button size='sm' variant='danger' onClick={() => {
                                showConfig(currentConfig);
                                setConfiguring(false);
                            }}>Cancelar</Button>
                            <Button size='sm' variant="dark" onClick={async () => {
                                let newErrors = {};
                                let isInvalid = false;
                                if (!appName) {
                                    newErrors = {...newErrors, appName: 'Nombre de la aplicacion inválido'}
                                    isInvalid = true;
                                }
                                if (!appKey) {
                                    newErrors = {...newErrors, appKey: 'Key de la aplicacion inválido'}
                                    isInvalid = true;
                                }
                                if (!appSecret) {
                                    newErrors = {...newErrors, appSecret: 'Secret de la aplicacion inválido'}
                                    isInvalid = true;
                                }
                                setErrors(newErrors)
                                if (!isInvalid) {
                                    const authorize = await setDropboxConfig({
                                        appName: appName,
                                        appKey: appKey,
                                        appSecret: appSecret
                                    });
                                    if (authorize.url) {
                                        setConfiguring(false);
                                        setConfirmation(true);
                                        setAuthorizeUrl(authorize.url);
                                    }
                                }
                            }}>
                                Configurar
                            </Button>
                        </>
                    }
                    {
                        confirmation && <>
                            <Button size='sm' variant='danger' onClick={() => {
                                setConfiguring(false);
                                setConfirmation(false);
                            }}>Cancelar</Button>
                            <Button size='sm' variant="dark" onClick={async () => {
                                if (!authorizationCode) {
                                    const newErrors = {
                                        ...errors,
                                        authorizationCode: 'Código de autorización inválido'
                                    }
                                    setErrors(newErrors);
                                } else {
                                    setLoading(true);
                                    try {
                                        const response = await setDropboxConfigCode(
                                            {appName:appName,code: authorizationCode}
                                        );
                                        if (response.success) {
                                            successMessage('La cuenta ha sido configurada correctamente');
                                            setConnected(true);
                                            setConfiguring(false);
                                            setConfirmation(false);
                                        } else {
                                            failMessage('Ha ocurrido un error al intentar configurar la cuenta');
                                        }
                                    } catch (err) {
                                        failMessage('Ha ocurrido un error al intentar configurar la cuenta');
                                    } finally {
                                        setLoading(false);
                                    }
                                }
                            }}>
                                Confirmar
                            </Button>
                        </>
                    }
                </div>


            </Form>


        </Container>
        <ModalMessage show={showMessage}
                      close={() => setShowMessage(false)}
                      error={error}
                      message={message}/>

    </>
}