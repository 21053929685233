import React from "react";
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {
    formatoMoneda,
    getSumaDescuentoSubservicios,
    getSumaPrecioSubservicios,
    getSumaSubtotalSubservicios
} from "../../utils";
import {CatalogoServicio, Subservicio} from "../../types/servicio";

export default function CatalogoServicioPanel(props: {
    catalogoServicio: CatalogoServicio,
    seleccionado: boolean,
    seleccionar: (e: CatalogoServicio) => void,
    editar: (e: CatalogoServicio) => void,
    downloadPreview?: () => void,
}) {

    const showDescuento = () => {
        const descuento = getSumaDescuentoSubservicios(props.catalogoServicio.subservicios);
        const precio = getSumaPrecioSubservicios(props.catalogoServicio.subservicios);
        return <>
            {
                descuento &&
                <div className="text-center" style={{paddingTop: "10px", paddingBottom: '10px'}}>
                    <div className='fs-6'>
                        <span>Precio real:</span>
                        <span style={{textDecoration: "line-through"}}>{formatoMoneda(precio)}</span>
                    </div>
                    <div>
                        <span className='fw-bold fs-6'>Descuento de {formatoMoneda(descuento)}</span>
                    </div>
                </div>

            }
        </>

    }

    return (
        <>
            <Card
                className={props.seleccionado ? 'servicio-seleccionado' : 'servicio'}>
                <Card.Body style={{cursor: 'pointer', height: '520px'}}
                           onClick={() => props.seleccionar(props.catalogoServicio)}>
                    <Card.Title>
                        <div style={{marginBottom: "10px"}}>
                            <Row>
                                <Col>
                                    <div className="text-center" style={{paddingTop: "20px"}}>
                                    <span className="fw-bold fs-5">
                                        {props.catalogoServicio.descripcion}
                                        {props.seleccionado && <i className='fs-3 bi bi-check'/>}
                                    </span>
                                    </div>
                                    <Row>
                                        <Col/>
                                        <Col sm='8'>
                                            <div className='d-flex justify-content-end'>
                                             <span className="fw-bold fs-3">
                                            {formatoMoneda(getSumaSubtotalSubservicios(props.catalogoServicio.subservicios))}
                                        </span>
                                            </div>
                                        </Col>
                                        <Col/>
                                    </Row>
                                    <Row>
                                        <Col/>
                                        <Col sm='8'>
                                            <div className='d-flex justify-content-end' style={{fontSize: '0.6em'}}>
                                                Sin iva.
                                            </div>
                                        </Col>
                                        <Col/>
                                    </Row>
                                    {
                                        showDescuento()
                                    }
                                </Col>
                            </Row>
                        </div>
                        <div className={props.seleccionado ? 'line-seleccionado' : 'line'}/>
                        <div style={{fontSize: "0.9rem", marginTop: '10px', height: '200px'}}>
                            {
                                props.catalogoServicio.subservicios.map((subservicio: Subservicio, index: number) =>
                                    <p><span className='bi bi-check-lg'/> {subservicio.descripcion}</p>)
                            }
                        </div>
                    </Card.Title>

                    {
                        (props.seleccionado && props.catalogoServicio.plantillaDescargable && props.downloadPreview) && <>
                            <Container className='d-flex justify-content-center'>
                                <img width='100' height='100'
                                     src='https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/.docx_icon.svg/240px-.docx_icon.svg.png'/>
                            </Container>

                            <Container className='d-flex justify-content-center' style={{marginBottom:'10px'}}>
                                <div>
                                    <label className='folderName fw-bold fs-5' style={{color: '#36acea'}}
                                           onClick={props.downloadPreview}>Descargar</label>
                                </div>
                            </Container>
                        </>
                    }

                    {
                        !props.catalogoServicio.plantillaDescargable &&
                        <p style={{color: 'gray', fontSize: '0.9rem'}}>
                            Aún no se ha configurado una plantilla de cotización en este servicio.
                            Puede agregar la plantilla en el menú "Catalogo de servicios" -{">"} "Editar"
                            -{">"} "Plantilla Word" -{">"} "Agregar plantilla"
                        </p>
                    }
                </Card.Body>
                <Card.Footer className="panel-servicio-footer">
                    {
                        props.seleccionado ?
                            <div className="text-center">
                                <Button variant='' className="fw-bold fs-5 text-white" onClick={() => {
                                    props.editar(props.catalogoServicio);
                                }}>
                                    Personalizar
                                </Button>
                            </div> : <div style={{height: '45px', width: '100%'}}/>
                    }

                </Card.Footer>
            </Card>

        </>

    );
}