import {Container} from "react-bootstrap";
import React from "react";
import {formatoMoneda} from "../../utils";

export default function TotalLabel(props: { total: number, withIva: boolean }) {
    return (
        <Container style={{paddingTop: "10px", paddingBottom: "20px", color: "black"}}>
            <div className='d-flex justify-content-end fw-bold fs-3'>
                Total: {formatoMoneda(props.total)} <span style={{fontSize: '0.8rem'}}>  MXN</span>
            </div>
            {
                !props.withIva &&
                <div className='d-flex justify-content-end' style={{fontSize: '0.7em'}}>
                    Sin iva.
                </div>
            }

        </Container>
    )
}