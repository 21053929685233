import {Button, Col, Container, Dropdown, DropdownButton, Row, Spinner} from "react-bootstrap";
import React, {useEffect, useRef, useState} from "react";
import {Actividad, Archivo, Servicio} from "../../types/servicio";
import {
    ACTIVIDAD_STATUS_FINALIZADO,
    formatoFechaTexto,
    getUsuarioSesion,
    isAdmin,
    isCliente,
    isCoordinador,
    isOperativo,
    isVentas,
    SUCCESS
} from "../../utils";
import ArchivoItem from "../documento/ArchivoItem";
import {
    cancelarActividad,
    cerrarActividad,
    eliminarArchivo,
    eliminarEvidencias,
    guardarEvidencias,
    rechazarActividad
} from "../../services/conem-services";
import Advertencia from "../mensajes/Advertencia";
import Observaciones from "./Observaciones";
import ConfirmacionModal from "../common/ConfirmacionModal";

export default function ActividadItem(
    props: {
        actividad: Actividad,
        servicio: Servicio,
        showErrorMessage?: (message: string) => void,
        updateService?: (service: Servicio) => void
    }
) {

    const [confirmarEliminarArchivo, setConfirmarEliminarArchivo] = useState<boolean>(false)
    const [archivoEliminar, setArchivoEliminar] = useState<Archivo | null>(null);


    const hiddenFileInput = useRef(null);

    const [localFilesSelected, setLocalFilesSelected] = useState<any>(null);
    const [archivos, setArchivos] = useState<Archivo[]>([]);
    const [archivosSeleccionados, setArchivosSeleccionados] = useState<Archivo[]>([])
    const [confirmarFinalizarActividad, setConfirmarFinalizarActividad] = useState<boolean>(false);
    const [confirmarCancelarActividad, setConfirmarCancelarActividad] = useState<boolean>(false);
    const [confirmarRechazarActividad, setConfirmarRechazarActividad] = useState<boolean>(false);

    const [eliminandoEvidencias, setEliminandoEvidencias] = useState<boolean>(false);


    useEffect(() => {
        setLocalFilesSelected(null);
        setArchivos(props.actividad.evidencias.archivos);
        setEliminandoEvidencias(false);
        setArchivosSeleccionados([]);
    }, []);

    const changeSelection = (archivo: Archivo, checked: boolean) => {
        if (!checked) {
            setArchivosSeleccionados(archivosSeleccionados.filter((item: Archivo) => item.id !== archivo.id));
        } else {
            setArchivosSeleccionados([...archivosSeleccionados, archivo]);
        }
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let valid = true;
        if (e.target.files && e.target.files.length > 0) {
            for (let i = 0; i < e.target.files.length; i++) {
                const file = e.target.files.item(i);
                if (file && (file.size <= 0 || file.size >= 50 * 1024 * 1024)) {
                    valid = false;
                    showErrorMessage("El tamaño mínimo de archivo es 1 byte y menor a 50 MB");
                    e.target.value = '';
                    return;
                }
            }
            if (valid) {
                const formData = new FormData();
                setLocalFilesSelected(e.target.files);
                for (let i = 0; i < e.target.files.length; i++) {
                    formData.append("files", e.target.files[i]);
                }
                guardarEvidencias(props.servicio, props.actividad, formData).then(
                    resp => {
                        if (resp.codigo === SUCCESS) {
                            const newArchivos = [...archivos, ...resp.object];
                            setArchivos(newArchivos);
                        } else {
                            showErrorMessage(resp.mensaje);
                        }
                    }
                ).catch(err => {
                    showErrorMessage("Lo sentimos ocurrió un error al subir los archivos de evidencias, por favor inténtelo mas tarde");
                }).finally(() => {
                    setLocalFilesSelected(null)
                });

            }
        }
    };

    const clickSelectFiles = () => {
        if (hiddenFileInput && hiddenFileInput.current) {
            // @ts-ignore
            hiddenFileInput.current.click();
        }
    }

    const enableActivityOptions = (actividad: Actividad) => {
        if (actividad && actividad.status === 'en_progreso') {

            if (isAdmin() || isVentas()) { //&& actividad.rolesPropietarios.includes("ROLE_ADMIN")) {
                return true;
            }
            if (!isCliente()) {
                return true;
            }
            /*const username = getUsuarioSesionUsername();
            if (isCoordinador() && actividad.rolesPropietarios.includes("ROLE_COORDINADOR")) {
                if (actividad.tipoPropietario === 'individual') {
                    if (props.servicio.coordinador && props.servicio.coordinador.correo === username) {
                        return true;
                    }
                } else if (actividad.tipoPropietario === 'role') {
                    return true;
                }
            }
            if (isOperador() && actividad.rolesPropietarios.includes("ROLE_OPERADOR")) {
                if (actividad.tipoPropietario === 'individual') {
                    if (props.servicio.operador && props.servicio.operador.correo === username) {
                        return true;
                    }
                } else if (actividad.tipoPropietario === 'role') {
                    return true;
                }
            }
            if (isCalidad() && actividad.rolesPropietarios.includes("ROLE_CALIDAD")) {
                if (actividad.tipoPropietario === 'individual') {
                    if (props.servicio.calidad && props.servicio.calidad.correo === username) {
                        return true;
                    }
                } else if (actividad.tipoPropietario === 'role') {
                    return true;
                }
            }*/
        }
        return false;
    }

    const isEnProgreso = () => {
        return props.actividad.status === 'en_progreso';
    }

    const showErrorMessage = (message: string) => {
        if (props.showErrorMessage) {
            props.showErrorMessage(message);
        }
    }

    const isEnabledUploadEvidencias = () => {
        return (getUsuarioSesion() !== null && !isCliente() && props.actividad.status === 'en_progreso');
    }

    const isEnableDeleteEvidencias = () => {
        return (archivosSeleccionados.length > 0 && isEnabledUploadEvidencias());
    }

    return (<div className={isEnProgreso() ? 'rounded' : ''}
                 style={isEnProgreso() ? {border: '1px dashed #307bbb', padding: '10px'} : {padding: '10px'}}>
            <input id="file" type="file" multiple={true} ref={hiddenFileInput} style={{display: 'none'}}
                   onChange={handleFileChange}/>
            <Row>
                <Col style={{borderRight: '1px solid #fafafa'}}>
                    <div style={{marginBottom: '10px', paddingRight: '10px'}}>
                        <label className={`fw-bold  fs-6 conem-text-actividad-activa`}>
                            {props.actividad.descripcion}</label>
                        {
                            (getUsuarioSesion() !== null && enableActivityOptions(props.actividad)) &&
                            <div>
                                {
                                    props.actividad.clave === 'validacion.informacion' &&
                                    <>
                                        <Button size='sm' variant='outline-dark'
                                                onClick={() => {
                                                    setConfirmarFinalizarActividad(true);
                                                }}>Información validada</Button>

                                        <Button size='sm' variant='outline-dark'
                                                disabled={!isCoordinador() && !isAdmin()}
                                                onClick={() => {
                                                    setConfirmarCancelarActividad(true);
                                                }}>Cancelar actividad</Button>
                                    </>
                                }
                                {
                                    (props.actividad.clave !== 'revision.calidad' && props.actividad.clave !== 'validacion.informacion') &&
                                    <>
                                        <Button size='sm' variant='outline-dark' disabled={archivos.length === 0}
                                                onClick={() => {
                                                    setConfirmarFinalizarActividad(true);
                                                }}>Finalizar actividad</Button>
                                        {
                                            (props.actividad.clave !== 'reporte.inspeccion') &&
                                            <Button size='sm' variant='outline-dark'
                                                    disabled={!isCoordinador() && !isAdmin()}
                                                    onClick={() => {
                                                        setConfirmarCancelarActividad(true);
                                                    }}>Cancelar actividad</Button>
                                        }
                                        {
                                            archivos.length === 0 && <div className='file-size'>
                                                <span>Para finalizar una actividad debe agregar al menos un archivo de evidencia</span>
                                            </div>
                                        }

                                    </>
                                }

                                {
                                    props.actividad.clave === 'revision.calidad' &&
                                    <>
                                        {
                                            archivos.length === 0 && <div className='file-size'>
                                                <span>Para finalizar una actividad debe agregar al menos un archivo de evidencia</span>
                                            </div>
                                        }
                                        <div className='d-inline-flex'>


                                            <DropdownButton
                                                disabled={isVentas() || isOperativo() || archivos.length === 0}
                                                size="sm" title="Actualizar">
                                                <Dropdown.Item eventKey="1" onClick={() => {
                                                    setConfirmarFinalizarActividad(true);
                                                }}>Finalizar actividad</Dropdown.Item>
                                                <Dropdown.Item eventKey="2" onClick={() => {
                                                    setConfirmarRechazarActividad(true);
                                                }}>Rechazar actividad</Dropdown.Item>
                                            </DropdownButton>
                                            <Button size='sm' variant='outline-dark' style={{marginLeft: '10px'}}
                                                    disabled={!isCoordinador() && !isAdmin()}
                                                    onClick={() => {
                                                        setConfirmarCancelarActividad(true);
                                                    }}>Cancelar actividad</Button>
                                        </div>
                                    </>

                                }
                            </div>
                        }
                    </div>

                    <Row>
                        {
                            (props.actividad.status === 'en_progreso' && props.actividad.clave === 'liberacion.anuencia') &&
                            <Container><Advertencia showIcon={false}
                                                    mensaje={'Esta actividad está asignada a la autoridad correspondiente por lo que tomará un\n' +
                                                        '                                    tiempo ' +
                                                        '                                    variable para su atención.'}/>
                            </Container>
                        }

                        <div>
                            <label style={{fontSize: '0.9em'}}>
                                Fecha estimada de finalización: <span
                                className='fw-bold'>{(props.actividad.clave !== 'liberacion.anuencia' && props.actividad.fechaEstimada) ? formatoFechaTexto(props.actividad.fechaEstimada) : '---'}</span>
                            </label>
                        </div>

                        {
                            props.actividad.status === ACTIVIDAD_STATUS_FINALIZADO &&
                            <div style={{marginBottom: '10px'}}>
                                <label style={{fontSize: '0.9em'}}>
                                    Fecha real de finalización: <span
                                    className='fw-bold'>{props.actividad.status === 'finalizado' ? formatoFechaTexto(props.actividad?.fechaFinalizado) : '---'}</span>
                                </label>
                            </div>
                        }
                    </Row>
                    {

                        <Observaciones actividad={props.actividad} servicio={props.servicio}/>
                    }

                </Col>
                {
                    props.actividad.clave !== 'validacion.informacion' &&
                    <Col style={{marginLeft: '10px'}}>
                        <Row>
                            <Col>
                                <label
                                    className={`fw-bold fs-6`}>
                                    Evidencias
                                </label>
                            </Col>
                            <Col sm='8'>
                                <div className='d-flex justify-content-end'>
                                    {
                                        isEnabledUploadEvidencias() &&
                                        <Button variant="outline-dark" size='sm' className='link-accion'
                                                disabled={localFilesSelected !== null}
                                                onClick={clickSelectFiles}>
                                            {
                                                localFilesSelected === null ? <>Subir evidencias</> : <>
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                    <span>Guardando evidencias...</span></>
                                            }
                                        </Button>
                                    }

                                    {
                                        isEnableDeleteEvidencias() &&
                                        <Button variant="outline-dark" size='sm' className='link-accion'
                                                onClick={() => {
                                                    setEliminandoEvidencias(true);
                                                    eliminarEvidencias(props.servicio, props.actividad, archivosSeleccionados).then(
                                                        resp => {
                                                            if (resp.codigo === SUCCESS) {
                                                                setArchivos(resp.object);
                                                                setArchivosSeleccionados([]);
                                                            } else {
                                                                showErrorMessage(resp.mensaje);
                                                            }
                                                        }
                                                    ).catch(err => {
                                                        showErrorMessage('Lo sentimos ocurrió un error al intentar eliminar las evidencias seleccionadas, por favor inténtelo más tarde');
                                                    }).finally(() => {
                                                        setEliminandoEvidencias(false)
                                                    });

                                                }}>
                                            {
                                                eliminandoEvidencias ? <><Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                /><span>Eliminando evidencias...</span></> : <>Eliminar evidencias</>

                                            }
                                        </Button>
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {
                                    (archivos.length === 0 && props.actividad.status === 'en_progreso') &&
                                    <label className="file-size" style={{fontSize: '0.9em'}}>
                                        Sin evidencias registradas
                                    </label>
                                }
                                {
                                    archivos.map((archivo: Archivo) => {
                                        return <ArchivoItem archivo={archivo}
                                                            servicioId={''}
                                                            documentoId={''}
                                                            statusServicio={'finalizado'}
                                                            seleccionable={props.actividad.status !== 'finalizado'}
                                                            eliminable={getUsuarioSesion() !== null && props.actividad.status !== 'finalizado'}
                                                            onDelete={(item: Archivo) => {
                                                                setArchivoEliminar(item);
                                                                setConfirmarEliminarArchivo(true);
                                                            }}
                                                            onSelect={(checked: boolean) => {
                                                                changeSelection(archivo, checked);
                                                            }}></ArchivoItem>
                                    })
                                }
                                {
                                    localFilesSelected &&
                                    [...localFilesSelected]?.map((file: any) =>
                                        <ArchivoItem archivo={file}
                                                     servicioId={''}
                                                     documentoId={''}
                                                     statusServicio={'finalizado'}
                                                     seleccionable={false} eliminable={false}
                                                     onSelect={() => {
                                                     }}/>
                                    )
                                }
                            </Col>

                        </Row>
                    </Col>
                }

            </Row>


            <ConfirmacionModal show={confirmarFinalizarActividad} onCancel={() => setConfirmarFinalizarActividad(false)}
                               title={'Confirmación'} onAccept={() => {
                cerrarActividad(props.servicio, props.actividad).then(resp => {
                    if (resp.codigo === SUCCESS) {
                        if (props.updateService) {
                            props.updateService(resp.object);
                        }
                    } else {
                        showErrorMessage(resp.mensaje);
                    }
                }).catch(err => {
                    showErrorMessage('Lo sentimos ocurrió un error al intentar cerrar la actividad, por favor inténtelo más tarde');
                }).finally(() => {
                    setConfirmarFinalizarActividad(false);
                })

            }} message={
                [`¿Está seguro de finalizar la actividad ${props.actividad.descripcion}?`]}
            />


            <ConfirmacionModal show={confirmarCancelarActividad} onCancel={() => setConfirmarCancelarActividad(false)}
                               title={'Confirmación'} onAccept={() => {
                cancelarActividad(props.servicio, props.actividad).then(resp => {
                    if (resp.codigo === SUCCESS) {
                        if (props.updateService) {
                            props.updateService(resp.object);
                        }
                    } else {
                        showErrorMessage(resp.mensaje);
                    }
                }).catch(err => {
                    showErrorMessage('Lo sentimos ocurrió un error al intentar cancelar la actividad, por favor inténtelo más tarde');
                }).finally(() => {
                    setConfirmarCancelarActividad(false);
                })

            }} message={
                [`¿Está seguro de cancelar la actividad ${props.actividad.descripcion}?`]}
            />

            <ConfirmacionModal show={confirmarRechazarActividad} onCancel={() => setConfirmarRechazarActividad(false)}
                               title={'Confirmación'} onAccept={() => {
                rechazarActividad(props.servicio, props.actividad).then(resp => {
                    if (resp.codigo === SUCCESS) {
                        if (props.updateService) {
                            props.updateService(resp.object);
                        }
                    } else {
                        showErrorMessage(resp.mensaje);
                    }
                }).catch(err => {
                    showErrorMessage('Lo sentimos ocurrió un error al intentar rechazar la actividad, por favor inténtelo más tarde');
                }).finally(() => {
                    setConfirmarRechazarActividad(false);
                })

            }} message={
                [`¿Está seguro de rechazar la actividad ${props.actividad.descripcion}?`]}
            />


            {
                archivoEliminar &&
                <ConfirmacionModal show={confirmarEliminarArchivo}
                                   onCancel={() => setConfirmarEliminarArchivo(false)}
                                   title={'Confirmación'} onAccept={() => {

                    setConfirmarEliminarArchivo(false);
                    eliminarArchivo(archivoEliminar).then(resp => {
                        if (resp.codigo === SUCCESS) {
                            setArchivos(resp.object)
                        } else {
                            showErrorMessage(resp.mensaje);
                        }
                    }).catch(err => {
                        showErrorMessage('Lo sentimos ocurrió un error al intentar eliminar el archivo, por favor inténtelo más tarde');
                    }).finally(() => {
                        setArchivoEliminar(null);
                    })
                }} message={[`Esta seguro de eliminar el archivo de evidencias ${archivoEliminar.nombre}`]}/>


            }
        </div>
    )
}