import {Actividad, Servicio} from "../../types/servicio";
import React, {useEffect, useState} from "react";
import ActividadItem from "./ActividadItem";

export default function ActividadList(props: {
    servicio: Servicio,
    actividades: Actividad[],
    showErrorMessage?:(message:string)=>void,
    updateService?:(servicio:Servicio)=>void
}) {

    const [actividades,setActividades]=useState<Actividad[]>([]);

    useEffect(() => {
        setActividades(props.actividades);
    }, [props.servicio]);

    return (
        <div className="stepper-container">
            {
                actividades?.map((item: Actividad, idx: number) => {
                    return (
                        <div
                            className={`step ${item.status === 'en_progreso' ? 'active' : (item.status === 'finalizado' ? 'completed' : '')}`}>
                            <div className="v-stepper">
                                <div className="stepper-circle"></div>
                                <div className="stepper-line"></div>
                            </div>
                            <div
                                className={idx < (props.actividades.length - 1) ? 'stepper-content border-bottom' : 'stepper-content'}>
                                <ActividadItem actividad={item}
                                               servicio={props.servicio}
                                               showErrorMessage={props.showErrorMessage}
                                               updateService={props.updateService}
                                />
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
}