import React, {useEffect, useState} from "react";
import {Button, Col, Container, Pagination, Row,} from "react-bootstrap";
import {getCotizaciones} from "../../services/conem-services";
import Papa from 'papaparse'
import TablaCotizaciones from "./TablaCotizaciones";
import {FiltroCotizacion, ServicePagination, Servicio} from "../../types/servicio";
import MensajeResultadoVacio from "../common/MensajeResultadoVacio";
import {formatoFechaSimple, isAdmin, isVentas} from "../../utils";
import PageHeader from "../common/PageHeader";
import FiltroCotizaciones from "./FiltroCotizaciones";
import ModalMessage from "../common/ModalMessage";
import {omit} from "lodash";
import Loader from "../common/Loader";
import cloneDeep from "lodash.clonedeep";
import Registros from "../paginacion/Registros";

export default function CotizacionList() {

    const exportData = (data: any, fileName: any, type: any) => {
        const blob = new Blob([data], {type});
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const [loading, setLoading] = useState(false)

    const [currentPage, setCurrentPage] = React.useState(0);
    const ROWS_PER_PAGE = 10;

    const [filtro, setFiltro] = useState<FiltroCotizacion>({
        rowsPerPage: ROWS_PER_PAGE,
        currentPage: currentPage,
        guardados: false,
        enviados: false,
        rechazados: false,
        expirados: false,
        riesgoBajo: false,
        riesgoMedio: false,
        riesgoAlto: false,
        nombreContacto: '',
        fechaRegistro: null,
        folio: null,
    });
    const [message, setMessage] = useState<string>('');
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        setCotizacionEdicion(null);
        filtrarCotizaciones(filtro);
    }, [])

    const downloadExcel = async () => {
        if (paginacion.totalElements === 0) {
            mostrarMensajeError("No existen registro para exportar")
        } else {
            const data = paginacion.items.map(item => (
                {
                    FOLIO: item.folio,
                    NOMBRE: item.cliente?.nombreCompleto,
                    TELEFONO: item.cliente?.telefono,
                    CORREO: item.cliente?.correo,
                    NOMBRE_COMERCIAL: item.cliente.detalle?.nombreComercial,
                    GIRO_COMERCIAL: item.cliente.detalle.giro,
                    DIRECCION: item.cliente.detalle.direccion,
                    MTRS_CONSTRUCCION: item.cliente.detalle.metrosConstruccion,
                    MTRS_TERRENO: item.cliente.detalle.metrosTerreno,
                    NUM_EMPLEADOS: item.cliente.detalle.numeroEmpleados,
                    SUSTANCIAS_QUIMICOS_PELIGROSOS: item.cliente.detalle.sustanciasQuimicasPeligrosas,
                    GAS_CAPACIDAD_ALMACENAMIENTO: item.cliente.detalle.gasCapacidadAlmacenamiento,
                    NIVEL_RIESGO: item.cliente.detalle.nivelRiesgo,
                    TIPO_CLIENTE: item.cliente.detalle.tipoCliente,
                    RAZON_SOCIAL: item.cliente.detalle.razonSocial,
                    RFC: item.cliente.detalle.rfc,
                    DOMICILIO_FISCAL: item.cliente.detalle.domicilioFiscal,
                    PRECIO: item.precio,
                    DESCUENTO: item.descuento,
                    IVA: item.iva,
                    TOTAL: item.total,
                    STATUS: item.status,
                    FECHA_REGISTRO: formatoFechaSimple(item.fechaRegistro),
                }
            ))
            const csvData = Papa.unparse(data);
            exportData(csvData, 'cotizaciones.csv', 'text/csv;charset=utf-8;');
        }
    }

    const filtrarCotizaciones = (newFiltro: FiltroCotizacion) => {
        setLoading(true);
        setFiltro(newFiltro);
        getCotizaciones(newFiltro).then(response => {
            setPaginacion(response);
        }).catch(() => {
            mostrarMensajeError('Lo sentimos ocurrió un error al realizar la consulta de cotizaciones');
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleChangePage = async (newPage: number) => {
        const newFiltro = {...filtro, currentPage: newPage}
        setCurrentPage(newPage);
        filtrarCotizaciones(newFiltro);
    };

    const mostrarMensajeError = (message: string) => {
        setError(true);
        setMessage(message);
        setShowMessage(true);
    }

    const [paginacion, setPaginacion] = useState<ServicePagination>({
        items: [],
        numberOfElements: 0,
        page: 0,
        totalElements: 0,
        totalPages: 0
    });

    const etiquetaFiltro = (key: string, value: string, field: string) => {
        return <Button size='sm' variant='secondary' className='filtro-item'
                       onClick={() => {
                           // @ts-ignore
                           const f: FiltroCotizacion = omit(filtro, field);
                           filtrarCotizaciones(f);
                       }}>
            {key}:
            <span className="badge" style={{color: 'black', backgroundColor: '#d8d8e6'}}>{value}</span>
        </Button>
    }

    const [cotizacionEdicion, setCotizacionEdicion] = useState<Servicio | null>(null);

    return (
        <>
            <Loader show={loading}/>
            {
                !cotizacionEdicion &&
                <Container>
                    <PageHeader label="Cotizaciones"/>
                    <Row>
                        <Col>
                            <FiltroCotizaciones aplicarFiltro={filtrarCotizaciones}/>
                            {
                                ((isAdmin() || isVentas()) && paginacion.numberOfElements > 0) &&
                                <Button variant='outline-dark' size='sm' style={{marginLeft: '10px'}}
                                        onClick={downloadExcel}>
                                    <i className="bi bi-file-spreadsheet-fill"/> Descargar
                                </Button>
                            }
                            <Button variant='outline-dark' size='sm' style={{marginLeft: '10px'}} onClick={() => {
                                filtrarCotizaciones(filtro);
                            }}>
                                <i className="bi bi-arrow-repeat"/> Actualizar
                            </Button>
                        </Col>
                        <Col>
                            {paginacion.numberOfElements > 0 &&
                                <Registros
                                    registros={`Registros ${((paginacion.page) * ROWS_PER_PAGE) + 1} al 
                                ${((paginacion.page) * ROWS_PER_PAGE) + paginacion.numberOfElements} - Página 
                                ${paginacion.page + 1} de ${paginacion.totalPages} - Total de registros ${paginacion.totalElements}`}/>
                            }
                        </Col>
                    </Row>
                    <div style={{marginBottom: '10px', marginTop: '10px'}}>
                        {
                            filtro.fechaRegistro && etiquetaFiltro('Fecha de registro', filtro.fechaRegistro, 'fechaRegistro')
                        }
                        {
                            filtro.nombreContacto && etiquetaFiltro('Nombre del contacto', filtro.nombreContacto, 'nombreContacto')
                        }
                        {
                            filtro.folio && etiquetaFiltro('Folio', filtro.folio, 'folio')
                        }
                        {
                            filtro.guardados && etiquetaFiltro('Status', 'Guardado', 'guardados')
                        }
                        {
                            filtro.enviados && etiquetaFiltro('Status', 'Enviado', 'enviados')
                        }
                        {
                            filtro.expirados && etiquetaFiltro('Status', 'Expirado', 'expirados')
                        }
                        {
                            filtro.rechazados && etiquetaFiltro('Status', 'Rechazado', 'rechazados')
                        }
                        {
                            filtro.riesgoBajo && etiquetaFiltro('Riesgo', 'Bajo', 'riesgoBajo')
                        }
                        {
                            filtro.riesgoMedio && etiquetaFiltro('Riesgo', 'Medio', 'riesgoMedio')
                        }
                        {
                            filtro.riesgoAlto && etiquetaFiltro('Riesgo', 'Alto', 'riesgoAlto')
                        }
                    </div>


                    {
                        paginacion.numberOfElements > 0 ?
                            <>
                                <TablaCotizaciones pagination={paginacion}
                                                   actualizar={() => filtrarCotizaciones(filtro)}
                                                   editar={(e: Servicio) => {
                                                       setCotizacionEdicion(cloneDeep(e));
                                                       //props.editar(e)
                                                   }}
                                                   onUpdate={(e: Servicio) => {
                                                       const items = paginacion.items.map((c: Servicio) => {
                                                           if (c.id === e.id) return e;
                                                           else return c;
                                                       });
                                                       setPaginacion({...paginacion, items: items});
                                                   }}

                                />
                                <div className="d-flex justify-content-center" style={{marginTop: "10px"}}>
                                    <Pagination>
                                        {paginacion.totalPages > 1 && Array(paginacion.totalPages).fill(1).map((el, i) =>
                                            <Pagination.Item active={i === paginacion.page} onClick={async () => {
                                                if (i !== paginacion.page) {
                                                    await handleChangePage(i);
                                                }
                                            }}>{i + 1}</Pagination.Item>
                                        )}
                                    </Pagination>
                                </div>

                                <div style={{marginTop: '40px'}}/>
                            </> :
                            <MensajeResultadoVacio/>
                    }
                </Container>
            }
            <ModalMessage show={showMessage} close={() => setShowMessage(false)} error={error} message={message}/>
        </>
    );
}