import {Archivo} from "../../types/servicio";
import {Col, Row, Spinner} from "react-bootstrap";
import React, {useState} from "react";
import {byteConverter, formatoFechaTiempo, getUsuarioSesion, truncarTexto} from "../../utils";
import DeleteButton from "../common/DeleteButton";

export default function ArchivoItem(props: {
    archivo: any,
    servicioId: string,
    documentoId: string,
    statusServicio: string,
    seleccionable: boolean,
    eliminable: boolean,
    onDelete?: (archivo: Archivo) => void,
    onSelect?: (checked: boolean) => void
}) {

    const [selected, setSelected] = useState<boolean>(false);

    /*useEffect(() => {
        setSelected(false);
    }, []);*/

    return (<>
        <Row className='rounded' style={{marginTop: '10px', padding: '5px'}}>
            <Col>
                <div className='d-inline-flex'>
                    {
                        (props.archivo.fechaSubido && props.seleccionable && props.onSelect) &&
                        <div style={{marginTop: '4px', marginRight: '5px'}}>
                            <input className="form-check-input" type="checkbox"
                                   checked={selected}
                                   onChange={(e) => {
                                       setSelected(e.target.checked);
                                       if (props.onSelect)
                                           props.onSelect(e.target.checked)
                                   }}/>
                        </div>
                    }

                    {
                        (!props.archivo.fechaSubido && getUsuarioSesion() !== null) ?
                            <div className='fs-6' style={{marginTop: '3px', color: 'gray'}}>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </div>
                            :
                            <span
                                className='fs-5 bi bi-file-earmark'/>
                    }
                    <div style={{marginLeft: '3px'}}>
                        <label className="folderName" style={{color: '#363535', fontSize: '0.9em'}} onClick={() => {
                            if (getUsuarioSesion() !== null && props.archivo.url) {
                                fetch(props.archivo.url)
                                    .then(response => {
                                        response.blob().then(blob => {
                                            let url = window.URL.createObjectURL(blob);
                                            let a = document.createElement('a');
                                            a.href = url;
                                            a.download = `${props.archivo.nombre}`;
                                            a.click();
                                        });
                                    });
                            }
                        }}>
                            {props.archivo.name ? truncarTexto(props.archivo.name, 100) : truncarTexto(props.archivo.nombre, 100)}
                        </label>
                        <div className='file-size'>
                            Tamaño: {props.archivo.tamanio ? byteConverter(props.archivo.tamanio, 2) : 'Pendiente'}
                        </div>
                        {
                            getUsuarioSesion()!==null &&
                            <>
                                <div className='file-size'>
                                    Fecha: {props.archivo.fechaSubido ? formatoFechaTiempo(props.archivo.fechaSubido) : 'Pendiente'}
                                </div>
                                <div className='file-size'>
                                    Actualizado
                                    por: {props.archivo.subidoPor ? `${props.archivo.subidoPor}` : 'Pendiente'}
                                </div>
                            </>
                        }
                    </div>
                </div>
            </Col>
            {
                props.eliminable && <Col sm='1'>
                    <div className='d-flex justify-content-end'>
                        <DeleteButton onClick={() => {
                            if (props.archivo?.subidoPor && props.onDelete) {
                                // @ts-ignore
                                props.onDelete(props.archivo);
                            }
                        }}/>
                    </div>
                </Col>
            }

        </Row>
    </>)
}