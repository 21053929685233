import React, {useEffect, useState} from "react";
import {Accordion, Button, Col, Container, Pagination, Row,} from "react-bootstrap";
import MensajeResultadoVacio from "../common/MensajeResultadoVacio";
import {Documento, FiltroServicio, ServicePagination, Servicio, Usuario} from "../../types/servicio";
import {
    getServicios,
    listAllCalidad,
    listAllCoordinadores,
    listAllOperativos,
    verificarDocumento
} from "../../services/conem-services";
import "react-datepicker/dist/react-datepicker.css";
import PageHeader from "../common/PageHeader";
import FiltroServicios from "./FiltroServicios";
import ServicioItem from "./ServicioItem";
import {getPorcentajeAvance, isAdmin, isCliente, isCoordinador, isVentas, SUCCESS} from "../../utils";
import Loader from "../common/Loader";
import {omit} from "lodash";
import ModalMessage from "../common/ModalMessage";
import Registros from "../paginacion/Registros";
import DashboardOperativo from "./DashboardOperativo";
import cloneDeep from "lodash.clonedeep";

export default function ServicioList(
    props: {
        hideSidebar: () => void,
        showSidebar: () => void,
        selectedService?: Servicio,
    }
) {

    const ROWS_PER_PAGE = 10;

    const [calidad, setCalidad] = useState<Usuario[]>([]);
    const [coordinadores, setCoordinadores] = useState<Usuario[]>([]);
    const [operativos, setOperativos] = useState<Usuario[]>([]);

    const [paginacion, setPaginacion] = useState<ServicePagination>({
        page: 0,
        numberOfElements: 0,
        totalElements: 0,
        totalPages: 0,
        items: [],
    });


    const [filtro, setFiltro] = useState<FiltroServicio>({
        rowsPerPage: ROWS_PER_PAGE,
        currentPage: paginacion.page,
    });
    const [loading, setLoading] = useState(false)

    useEffect(() => {

        setLoading(true);

        if (isAdmin() || isVentas()) {

            listAllCoordinadores().then(resp => {
                if (resp.codigo === SUCCESS) {
                    setCoordinadores(resp.object);
                } else {
                    setCoordinadores([]);
                }
            }).catch(err => console.error(err));


            listAllCalidad().then(resp => {
                if (resp.codigo === SUCCESS) {
                    setCalidad(resp.object);
                } else {
                    setCalidad([])
                }
            }).catch(err => console.error(err));


            listAllOperativos().then(data => {
                if (data.codigo === SUCCESS) {
                    setOperativos(data.object);
                } else {
                    setOperativos([]);
                }
            }).catch(err => console.error(err));


        }

        if (isCoordinador()) {
            listAllOperativos().then(data => {
                if (data.codigo === SUCCESS) {
                    setOperativos(data.object);
                } else {
                    setOperativos([]);
                }
            }).catch(err => console.error(err));
        }


        getServicios(filtro).then(data => {
            setPaginacion(data)
        }).catch(async err => {
            /*if (err.response?.status === 403) {
                await doLogout();
                window.location.href = 'http://localhost:3000/login';
            }*/
        }).finally(() => setLoading(false))
    }, [])

    useEffect(() => {
        if (props.selectedService) {
            setServiceSelected(props.selectedService);
        } else {
            setServiceSelected(null)
        }
    }, [props.selectedService]);

    const handleChangePage = async (newPage: number) => {
        setLoading(true);
        const newFilter = {...filtro, currentPage: newPage};
        getServicios(newFilter).then(data => {
            setFiltro(newFilter);
            setPaginacion(data);
        }).catch(err => console.error(err)).finally(() => setLoading(false))
    };

    const aplicarFiltro = (newFiltro: FiltroServicio) => {
        setFiltro(newFiltro);
        setLoading(true);
        getServicios(newFiltro).then(data => {
            setPaginacion(data);
        }).catch(err => mostrarMensajeError('Lo sentimos ocurrió un error al realizar la consulta de servicios'))
            .finally(() => setLoading(false))
    }

    const etiquetaFiltro = (key: string, value: string, field: string) => {
        return <Button size='sm' variant='secondary' className='filtro-item'
                       onClick={async () => {
                           // @ts-ignore
                           const newFiltro: FiltroServicio = omit(filtro, field);
                           await aplicarFiltro(newFiltro);
                       }}>
            {key}=<span className="badge"
                        style={{color: 'black', backgroundColor: '#d8d8e6'}}>{value}</span>
        </Button>
    }

    const [message, setMessage] = useState<string>('');
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const mostrarMensajeError = (message: string) => {
        setError(true);
        setMessage(message);
        setShowMessage(true);
    }

    const [serviceSelected, setServiceSelected] = useState<Servicio | null>(null);

    return (
        <>
            {
                (serviceSelected === null) &&
                <>
                    <Loader show={loading}/>
                    <Container>
                        <PageHeader label="Servicios"/>
                        {
                            !isCliente() &&
                            <>
                                {
                                    isCoordinador() && <Row>
                                        <div style={{marginBottom: '10px'}}>
                                            <DashboardOperativo/>
                                        </div>
                                        <hr/>
                                    </Row>
                                }
                                <Row>
                                    <Col>
                                        <FiltroServicios aplicarFiltro={(newFiltro: FiltroServicio) => {
                                            aplicarFiltro(newFiltro);
                                        }}/>
                                        {
                                            ((isAdmin() || isVentas()) && paginacion.numberOfElements > 0) &&
                                            <Button variant='outline-dark' size='sm' style={{marginLeft: '10px'}}>
                                                <i className="bi bi-file-spreadsheet-fill"/> Descargar
                                            </Button>
                                        }
                                        <Button variant='outline-dark' size='sm' style={{marginLeft: '10px'}}
                                                onClick={() => {
                                                    aplicarFiltro(filtro);
                                                }}>
                                            <i className="bi bi-arrow-repeat"/> Actualizar
                                        </Button>

                                    </Col>
                                    <Col>
                                        {
                                            paginacion.numberOfElements > 0 &&
                                            <Registros
                                                registros={`Registros ${((paginacion.page) * ROWS_PER_PAGE) + 1} al ${((paginacion.page) * ROWS_PER_PAGE) + paginacion.numberOfElements} - Página ${paginacion.page + 1} de ${paginacion.totalPages} - Total de registros ${paginacion.totalElements}`}/>
                                        }
                                    </Col>
                                </Row>

                                <div style={{marginTop: '10px'}}>
                                    {
                                        filtro.fechaAceptacion && etiquetaFiltro('Fecha de aceptación', filtro.fechaAceptacion, 'fechaAceptacion')
                                    }
                                    {
                                        filtro.nombreCliente && etiquetaFiltro('Nombre del cliente', filtro.nombreCliente, 'nombreCliente')
                                    }
                                    {
                                        filtro.documentosFaltantes && etiquetaFiltro('Con documentos faltantes', 'True', 'documentosFaltantes')
                                    }
                                    {
                                        filtro.fechaInspeccion && etiquetaFiltro('Sin fecha de inspección', 'True', 'fechaInspeccion')
                                    }
                                    {
                                        filtro.fechaCapacitacion && etiquetaFiltro('Sin fecha de capacitación', 'True', 'fechaCapacitacion')
                                    }
                                    {
                                        filtro.coordinadorAsignado && etiquetaFiltro('Sin coordinador asignado', 'True', 'coordinadorAsignado')
                                    }
                                    {
                                        filtro.operadorAsignado && etiquetaFiltro('Sin operativo asignado', 'True', 'operadorAsignado')
                                    }
                                    {
                                        filtro.calidadAsignado && etiquetaFiltro('Sin personal de calidad asignado', 'True', 'calidadAsignado')
                                    }
                                    {
                                        filtro.instructorAsignado && etiquetaFiltro('Sin instructor asignado', 'True', 'instructorAsignado')
                                    }
                                    {
                                        filtro.observaciones && etiquetaFiltro('Con observaciones', 'True', 'observaciones')
                                    }
                                    {
                                        filtro.finalizado && etiquetaFiltro('Finalizado', 'True', 'finalizado')
                                    }
                                    {
                                        filtro.progreso && etiquetaFiltro('En progreso', 'True', 'progreso')
                                    }
                                    {
                                        filtro.pendientePago && etiquetaFiltro('Pendiente de pago', 'True', 'pendientePago')
                                    }
                                </div>
                            </>
                        }
                    </Container>
                    <Container style={{marginTop: "10px", marginBottom: '40px'}}>
                        {
                            paginacion.numberOfElements > 0 ?
                                <>
                                    <Accordion>
                                        {
                                            paginacion.items.map((servicio: Servicio, index: number) => {

                                                return <ServicioItem
                                                    avance={getPorcentajeAvance(servicio)}
                                                    index={index}
                                                    servicio={servicio}
                                                    calidad={calidad}
                                                    coordinadores={coordinadores}
                                                    operadores={operativos}
                                                    updateService={(e: Servicio) => {
                                                        const newItems = paginacion.items.map((s: Servicio) => {
                                                            if (s.id === e.id) return e;
                                                            else return s;
                                                        })
                                                        const newPag = cloneDeep({...paginacion, items: newItems})
                                                        setPaginacion(newPag);
                                                    }}
                                                    verificarDocumento={(documento: Documento, verificado: boolean) => {
                                                        if (servicio.id && documento.id) {
                                                            verificarDocumento(servicio.id, documento.id, verificado).then(data => {
                                                                if (data.codigo === SUCCESS) {
                                                                    const newDocumentos = servicio.documentos.map((doc: Documento) => {
                                                                        if (doc.id === documento.id) return documento;
                                                                        return doc;
                                                                    })
                                                                    const newServicio = {
                                                                        ...servicio,
                                                                        documentos: newDocumentos
                                                                    };
                                                                    const newItems = paginacion.items.map((s: Servicio) => {
                                                                        if (s.id === servicio.id) return newServicio;
                                                                        else return s;
                                                                    });
                                                                    setPaginacion({...paginacion, items: newItems});
                                                                }
                                                            }).catch(error => {

                                                            })
                                                        }
                                                    }}
                                                    actualizar={(servicio: Servicio) => {
                                                        const newItems = paginacion.items.map((s: Servicio) => {
                                                            if (s.id === servicio.id) return servicio;
                                                            else return s;
                                                        });
                                                        setPaginacion({...paginacion, items: newItems});
                                                    }}
                                                    onSelect={() => {
                                                        setServiceSelected(servicio);
                                                        props.hideSidebar();
                                                    }}
                                                />


                                            })
                                        }
                                    </Accordion>
                                    <div className="d-flex justify-content-center" style={{marginTop: "10px"}}>
                                        <Pagination>
                                            {paginacion.totalPages > 1 && Array(paginacion.totalPages).fill(1).map((el, i) =>
                                                <Pagination.Item active={i === paginacion.page} onClick={async () => {
                                                    if (i !== paginacion.page) {
                                                        setLoading(true)
                                                        await handleChangePage(i);
                                                    }
                                                }}>{i + 1}</Pagination.Item>
                                            )}
                                        </Pagination>
                                    </div>

                                </>
                                : <MensajeResultadoVacio/>
                        }
                    </Container>
                </>
            }
            {
                serviceSelected && <Container style={{marginTop: '10px'}}>
                    <ServicioItem
                        avance={getPorcentajeAvance(serviceSelected)}
                        index={-1}
                        servicio={serviceSelected}
                        calidad={calidad}
                        coordinadores={coordinadores}
                        operadores={operativos}
                        updateService={(e: Servicio) => {
                            const newItems = paginacion.items.map((s: Servicio) => {
                                if (s.id === e.id) return e;
                                else return s;
                            })
                            const newPag = cloneDeep({...paginacion, items: newItems})
                            setPaginacion(newPag);
                        }}
                        verificarDocumento={(documento: Documento, verificado: boolean) => {
                            if (serviceSelected.id && documento.id) {
                                verificarDocumento(serviceSelected.id, documento.id, verificado).then(data => {
                                    if (data.codigo === SUCCESS) {
                                        const newDocumentos = serviceSelected.documentos.map((doc: Documento) => {
                                            if (doc.id === documento.id) return documento;
                                            return doc;
                                        })
                                        const newServicio = {
                                            ...serviceSelected,
                                            documentos: newDocumentos
                                        };
                                        const newItems = paginacion.items.map((s: Servicio) => {
                                            if (s.id === serviceSelected.id) return newServicio;
                                            else return s;
                                        });
                                        setPaginacion({...paginacion, items: newItems});
                                    }
                                }).catch(error => {

                                })
                            }
                        }}
                        actualizar={(servicio: Servicio) => {
                            const newItems = paginacion.items.map((s: Servicio) => {
                                if (s.id === servicio.id) return servicio;
                                else return s;
                            });
                            setPaginacion({...paginacion, items: newItems});
                        }}
                        onSelect={() => {
                            setServiceSelected(null);
                            props.showSidebar();
                        }}
                    />
                </Container>
            }

            <ModalMessage show={showMessage} close={() => setShowMessage(false)} error={error} message={message}/>
        </>
    )
}