import React from 'react'
import {Navigate} from 'react-router-dom'

function Protected({isAuthenticated, children, redirectPath}) {
    if (!isAuthenticated) {
        return <Navigate to={redirectPath} replace/>
    }
    return children
}

export default Protected