import {Col, Container, Row, Spinner} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import PublicNavbar from "../publico/PublicNavbar";
import {useParams} from "react-router-dom";
import {isVinculoValido} from "../../services/conem-services";
import FormNuevaContrasenia from "./FormNuevaContrasenia";
import {SUCCESS} from "../../utils";

export default function FormCambiarContrasenia() {

    const {id} = useParams();
    const [expirado, setExpirado] = useState<boolean>(false);
    const [valido, setValido] = useState<boolean>(false);

    useEffect(() => {
        setExpirado(false);
        setValido(false);
        if (id) {
            isVinculoValido(id).then(resp => {
                if (resp.codigo === SUCCESS) {
                    setValido(true);
                } else if (resp.codigo === 404) {
                    setExpirado(true);
                }
            }).finally(
            )
        }
    }, []);

    return (
        <>
            <PublicNavbar/>
            <Row className="align-items-center" style={{height: '65vh'}}>
                <Col>
                    {
                        (!expirado && !valido) &&
                        <center>
                            <Spinner animation="border"/>
                            <div>
                                Espere un momento por favor estamos verificando la página solicitada
                            </div>
                        </center>
                    }
                    {
                        expirado &&
                        <Container style={{paddingTop: "10px", color: '#d0cece'}}>
                            <center>
                                <h2>Lo sentimos al parecer la página no existe o ya expiró</h2>
                            </center>
                        </Container>
                    }
                    {
                        valido && <FormNuevaContrasenia/>
                    }
                </Col>
            </Row>
        </>
    )

}