import {Button, Container, Form, Row} from "react-bootstrap";
import CheckFilter from "../common/CheckFilter";
import React, {useEffect, useState} from "react";
import {FiltroUsuario, Grupo} from "../../types/servicio";
import {listGroups} from "../../services/conem-services";

export default function UsuarioFiltro(props: { onFilter: (e: FiltroUsuario) => void }) {


    const [fullName, setFullName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [admins, setAdmins] = useState<boolean>(false);
    const [ventas, setVentas] = useState<boolean>(false);
    const [managers, setManagers] = useState<boolean>(false);
    const [operators, setOperators] = useState<boolean>(false);
    const [qa, setQa] = useState<boolean>(false);
    const [clients, setClients] = useState<boolean>(false);
    const [grupos, setGrupos] = useState<Grupo[] | null>(null);

    useEffect(() => {
        listGroups().then(data => {
            setGrupos(data);
        }).catch(err => {

        })
    }, []);

    return (
        <>
            <div className='filter'>
                <Button variant='outline-dark' size='sm'>
                    <i className="bi bi-funnel-fill"/> Filtrar
                </Button>
                <div className='filter-content rounded'>
                    <Row>
                        <span className="text-muted" style={{fontSize: "0.8rem"}}>Nombre del usuario</span>
                    </Row>
                    <Container>
                        <Row className="row-cols-auto">
                            <Form.Control type="text" style={{width: "300px"}} value={fullName}
                                          maxLength={300}
                                          onChange={(e) => {
                                              setFullName(e.target.value)
                                          }}
                                          onBlur={
                                              (e) => {
                                                  //setNameFilter({...filter, name: filtroNombreContacto});
                                              }}>

                            </Form.Control>
                        </Row>
                    </Container>
                    <Row>
                        <span className="text-muted" style={{fontSize: "0.8rem"}}>Correo electrónico</span>
                    </Row>
                    <Container>
                        <Row className="row-cols-auto">
                            <Form.Control type="text" style={{width: "300px"}} value={email}
                                          maxLength={300}
                                          onChange={(e) => {
                                              setEmail(e.target.value)
                                          }}
                                          onBlur={
                                              (e) => {
                                                  //setNameFilter({...filter, name: filtroNombreContacto});
                                              }}>

                            </Form.Control>
                        </Row>
                    </Container>
                    <hr/>
                    <Row>
                        <span className="text-muted" style={{fontSize: "0.8rem"}}>Grupo</span>
                    </Row>
                    <Container>
                        {
                            grupos?.map((g: Grupo) => {
                                return <Row>
                                    <CheckFilter selected={g.checked} label={g.descripcion}
                                                 onChange={(checked: boolean) => {
                                                     setGrupos(grupos?.map((gr: Grupo) => {
                                                         if (gr.id === g.id) return {...g, checked: checked}
                                                         else return gr;
                                                     }))
                                                 }}/>
                                </Row>
                            })
                        }
                    </Container>
                    <hr/>
                    <Row>
                        <span className="text-muted" style={{fontSize: "0.8rem"}}>Rol</span>
                    </Row>
                    <Container>
                        <Row>
                            <CheckFilter label="Administrador" id="administrador" selected={admins}
                                         onChange={(checked: boolean) => {
                                             setAdmins(checked);
                                         }}/>
                        </Row>
                        <Row>
                            <CheckFilter label="Ventas" id="ventas" selected={ventas}
                                         onChange={(checked: boolean) => {
                                             setVentas(checked);
                                         }}/>
                        </Row>
                        <Row>
                            <CheckFilter label="Coordinador" id="coordinador" selected={managers}
                                         onChange={(checked: boolean) => {
                                             setManagers(checked);
                                         }}/>
                        </Row>
                        <Row>
                            <CheckFilter label="Operativo" id="operativo" selected={operators}
                                         onChange={(checked: boolean) => {
                                             setOperators(checked);
                                         }}/>
                        </Row>
                        <Row>
                            <CheckFilter label="Calidad" id="calidad" selected={qa} onChange={(checked: boolean) => {
                                setQa(checked)
                            }}/>
                        </Row>
                        <Row>
                            <CheckFilter label="Cliente" id="cliente" selected={clients}
                                         onChange={(checked: boolean) => {
                                             setClients(checked);
                                         }}/>
                        </Row>
                    </Container>
                    <hr/>
                    <div className='d-flex justify-content-center' style={{paddingTop: "5px"}}>
                        <Button variant="primary" size='sm' style={{width: '150px'}} onClick={() => {
                            let filter: FiltroUsuario = {
                                rowsPerPage: 10,
                                currentPage: 0,
                                fullName: fullName,
                                email: email,
                                admins: admins,
                                managers: managers,
                                qas: qa,
                                operators: operators,
                                clients: clients,
                                ventas: ventas,
                                grupos: []
                            };
                            if (grupos) {
                                for(let i=0; i<grupos.length; i++){
                                    if (grupos[i].checked) {
                                        filter.grupos?.push(grupos[i].id)
                                    }
                                }
                            }
                            props.onFilter(filter);
                        }}>
                            Aplicar filtro
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}