import {Form} from "react-bootstrap";
import React, {useState} from "react";

export default function CheckFilter(props: any) {
    const [selected, setSelected] = useState<boolean>(false);
    return (
        <Form.Check className={selected ? `checkFilter text-black` : `checkFilter`}
                    inline onChange={(e) => {
            setSelected(e.target.checked);
            props.onChange(e.target.checked);
        }}
                    label={props.label}
                    name="group1"
                    type='checkbox'
                    id={props.id}
        />
    )
}