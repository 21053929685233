import {Button, Modal} from "react-bootstrap";
import React from "react";

export default function ModalMessage(props: { show: boolean, close: any, message: string, error: boolean }) {
    return (
        <Modal id="mensaje" size="lg" show={props.show} onHide={props.close}>
            <Modal.Header style={{backgroundColor: "#000000", color: 'white', height: "60px"}}>
                <Modal.Title style={{fontSize: "1.2rem"}}>Mensaje</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    !props.error ?
                        <span style={{color: 'green'}}><i className='bi bi-check-circle-fill'></i></span> :
                        <span className='fs-4' style={{color: 'red'}}><i className='bi bi-exclamation-circle-fill'></i></span>
                }
                <span style={{fontSize: "1rem"}}>  {props.message}</span>
            </Modal.Body>
            <Modal.Footer style={{backgroundColor: "#fafafa", height: "50px", paddingTop: '5px'}}>
                <Button size='sm' variant="primary" onClick={props.close}>
                    Aceptar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}