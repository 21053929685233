import {Button, Container, Modal} from "react-bootstrap";
import React from "react";

export default function ConfirmacionModal(props: {
    show: boolean,
    onCancel: () => void,
    title: string,
    onAccept: () => void,
    message: string[]
}) {
    return (<>
            <Modal size="lg" className="modal-dialog-centered" show={props.show}>
                <Modal.Header className='modal-header'>
                    <Modal.Title  style={{fontSize:'0.9rem'}}>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <div className='d-inline-flex'>
                            <div style={{marginTop:'10px',marginRight:'10px'}}>
                                <span className='fs-4 bi bi-exclamation-circle-fill' style={{color: 'red'}}/>
                            </div>
                            <div>
                                <div style={{marginTop: '10px',fontSize:'0.9rem'}}>
                                    <span>{props.message[0]}</span>
                                    {
                                        props.message.length > 1 &&
                                        <div>
                                            {props.message[1]}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </Container>
                </Modal.Body>
                <Modal.Footer style={{padding: '5px'}}>
                    <Button size='sm' variant="danger" onClick={props.onCancel}>Cancelar</Button>
                    <Button size='sm' variant="dark" onClick={props.onAccept}>Aceptar</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}