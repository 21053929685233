import FormEvaluacionSatisfaccion from "../servicio/FormEvaluacionSatisfaccion";
import React, {useCallback, useEffect, useState} from "react";
import {EncuestaSatisfaccion, Servicio} from "../../types/servicio";
import {getUsuarioSesion, SUCCESS} from "../../utils";
import {getPublicServiceDetails, getServiceDetails, guardarEncuestaSatisfaccion} from "../../services/conem-services";
import {useParams} from "react-router-dom";
import PublicNavbar from "./PublicNavbar";
import {Container} from "react-bootstrap";
import PageHeader from "../common/PageHeader";

export default function PageEvaluacionSatisfaccion() {

    const {id} = useParams();

    const [error, setError] = useState<{ message: string } | null>(null);

    const [servicio, setServicio] = useState<Servicio | null>(null);
    const [showMensajeDespedida, setShowMensajeDespedida] = useState(false);


    const fetchData = useCallback(async (id: string) => {
        if (getUsuarioSesion()) {
            return getServiceDetails(id);
        } else {
            return getPublicServiceDetails(id);
        }
    }, []);

    useEffect(() => {
        setError(null);
        setShowMensajeDespedida(false);
        setServicio(null);
        try {
            //@ts-ignore
            let real = atob(id);
            fetchData(real).then(resp => {
                if (resp.codigo === SUCCESS) {
                    if (resp.object.encuestaSatisfaccion) {
                        setError({message: 'Oops!  al parecer la página que busca no existe'});
                    } else {
                        setServicio(resp.object);
                    }

                } else {
                    setError({message: 'Oops!  al parecer ha ocurrido un error, por favor inténtelo más tarde'});
                }
            }).catch(error => {
                setError({message: 'Oops!  al parecer ha ocurrido un error, por favor inténtelo más tarde'});
            });
        } catch (e) {
            setError({message: 'Oops!  al parecer ha ocurrido un error, por favor inténtelo más tarde'});
        }
    }, []);

    return (
        <>
            <PublicNavbar/>
            {showMensajeDespedida &&
                <Container style={{paddingTop: "100px"}}>
                    <center>
                        <h2>Agradecemos haya respondido nuestra encuesta, esperamos pronto poder colaborar juntos.</h2>
                    </center>
                </Container>
            }
            {
                (!showMensajeDespedida && error) &&
                <Container style={{paddingTop: "100px"}}>
                    <center>
                        <h2>{error.message}</h2>
                    </center>
                </Container>
            }
            {
                (!showMensajeDespedida && !error && servicio) &&
                <Container style={{marginTop: '20px'}}>
                    <PageHeader label={`Evaluación de satisfacción:  ${servicio.folio}`}/>
                    <hr/>
                    <FormEvaluacionSatisfaccion servicio={servicio}
                                                guardar={(e: EncuestaSatisfaccion) => {
                                                    if (servicio.id) {
                                                        guardarEncuestaSatisfaccion(servicio.id, e).then(resp => {
                                                            if (resp.codigo === SUCCESS) {
                                                                setShowMensajeDespedida(true)
                                                            } else {
                                                                setError({message: resp.mensaje})
                                                            }
                                                        }).catch(err => setError({message: "Lo sentimos ocurrió un error al intentar guardar la encuesta de satisfacción"}))
                                                    }
                                                }}/>
                </Container>
            }
        </>
    )
}