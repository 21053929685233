import {EditText} from "react-edit-text";
import React, {useEffect, useState} from "react";
import {Tarea} from "../../types/servicio";
import {truncarTexto} from "../../utils";


export default function TareaItem(props: {
    tarea: Tarea,
    eliminable: boolean,
    onDelete?: () => void,
    onChange?: (value: string) => void,
}) {

    const [descripcion, setDescripcion] = useState<string>('');

    useEffect(() => {
        setDescripcion(props.tarea.descripcion)
    }, [props.tarea]);

    return (
        <div>
            <div className='d-inline-flex'>
                <span className='bi bi-check-circle' style={{fontSize: '0.7em', marginTop: '10px'}}/>
                <div style={{minWidth: '60%'}}>
                    <EditText style={{fontSize: '0.8em', color: '#3a3939'}}
                              formatDisplayText={(value) => truncarTexto(value, 100)} value={descripcion}
                              onChange={(e) => setDescripcion(e.target.value)}
                              onSave={({name, value, previousValue}) => {
                                  if (props.onChange) props.onChange(value)
                              }}
                    />
                </div>
                {
                    props.eliminable &&
                    <span className='bi bi-x-circle-fill clickable'
                          style={{fontSize: '0.7em', marginTop: '10px', color: 'gray'}}
                          onClick={() => {
                              if (props.onDelete) {
                                  props.onDelete();
                              }
                          }}/>
                }
            </div>
        </div>
    )
}