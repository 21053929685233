import React from "react";

export default function Loading(props: { cargando: boolean }) {
    return (
        <>
            {
                props.cargando ?
                    <div className='d-flex justify-content-center'>
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div> : <div></div>
            }
        </>
    )
};