import {Col, ListGroup, Row, Tab, Tabs} from "react-bootstrap";
import IndicadorItem from "../estadisticas/IndicadorItem";
import React, {useEffect, useState} from "react";
import {Servicio} from "../../types/servicio";
import {getEstadisticaMes} from "../../services/conem-services";
import {SUCCESS} from "../../utils";

export default function DashboardOperativo() {

    const [estadisticaMes, setEstadisticaMes] = useState<{
        pipcRiesgoBajo: Servicio[],
        pipcRiesgoMedio: Servicio[],
        promedioDesarrolloServicioPorOperativo: number,
        pipcEnDesarrollo: Servicio[],
        pipcIngresados: Servicio[],
        pipcLiberados: Servicio[],
        pipcObservaciones: Servicio[],
        pipcAtrasados: Servicio[],
        evaluacionesSatisfaccionSuperior95: Servicio[],
        evaluacionesSatisfaccionRealizadas: Servicio[],
        evaluacionesSatisfaccionPendientes: Servicio[],
        evaluacionesSatisfaccionSuperior70: Servicio[],
        evaluacionesSatisfaccionMenor70: Servicio[],
    }
    >({
        pipcRiesgoBajo: [],
        pipcRiesgoMedio: [],
        promedioDesarrolloServicioPorOperativo: 0,
        pipcEnDesarrollo: [],
        pipcIngresados: [],
        pipcLiberados: [],
        pipcObservaciones: [],
        pipcAtrasados: [],
        evaluacionesSatisfaccionSuperior95: [],
        evaluacionesSatisfaccionRealizadas: [],
        evaluacionesSatisfaccionPendientes: [],
        evaluacionesSatisfaccionSuperior70: [],
        evaluacionesSatisfaccionMenor70: [],
    });

    const cargarEstadisticaMes = () => {

        getEstadisticaMes().then(resp => {
            if (resp.codigo === SUCCESS) {
                setEstadisticaMes(resp.object);
            }
        }).catch(err => console.log(err)).finally(
            () => {

            }
        )

    }

    function porcentajeText(value: number): string {
        if (value)
            return `${Math.trunc(value)}%`
        return '---'
    }

    useEffect(() => {
        cargarEstadisticaMes();
    }, []);


    const tiempoPromedioPipcRiesgoBajo = (pipcRiesgoBajo: Servicio[]) => {
        let label = ''
        if (pipcRiesgoBajo && pipcRiesgoBajo.length > 0) {
            let sum = pipcRiesgoBajo.reduce(function (a: number, b: Servicio) {
                return a + (b.primeraEtapaOperativa ? b.primeraEtapaOperativa : 0)
            }, 0);
            label = `${sum / pipcRiesgoBajo.length} días`;

        } else {
            label = '---'
        }
        return <IndicadorItem titulo={'Tiempo promedio de desarrollo de PIPC Riesgo Bajo'}
                              valor={label}/>
    }
    const tiempoPromedioPipcRiesgoMedio = (pipcRiesgoMedio: Servicio[]) => {
        let label = ''
        if (pipcRiesgoMedio && pipcRiesgoMedio.length > 0) {
            let sum = pipcRiesgoMedio.reduce(function (a: number, b: Servicio) {
                return a + (b.primeraEtapaOperativa ? b.primeraEtapaOperativa : 0)
            }, 0);
            label = `${sum / pipcRiesgoMedio.length} días`;
        } else {
            label = '---'
        }
        return <IndicadorItem titulo={'Tiempo promedio de desarrollo de PIPC Riesgo Medio'}
                              valor={label}/>
    }


    return (
        <Tabs defaultActiveKey='tiempoDesarrolloPIPC' id='estadisticasTab'
              className='mb-3' style={{marginTop: '5px'}}>
            <Tab eventKey='tiempoDesarrolloPIPC' title='Tiempo de desarrollo de PIPC'>
                <div style={{marginTop: '20px', borderBottom: '1px solid #c1c1c1'}}>
                    <Row>
                        <Col>{tiempoPromedioPipcRiesgoBajo(estadisticaMes.pipcRiesgoBajo)}</Col>
                        <Col>{tiempoPromedioPipcRiesgoMedio(estadisticaMes.pipcRiesgoMedio)}</Col>
                        <Col>
                            <IndicadorItem titulo={'Tiempo promedio de desarrollo del servicio por Operativo '}
                                           valor={`${estadisticaMes.promedioDesarrolloServicioPorOperativo} días`}/>
                        </Col>
                    </Row>
                </div>
                <div style={{marginTop: '20px'}}>
                    <ListGroup>
                        <ListGroup.Item className='fw-bold' key={'headerDetalles'}
                                        style={{backgroundColor: '#3a3939', color: 'white'}}>
                            <Row>
                                <Col sm='6'>Folio</Col>
                                <Col sm='3'>Nivel de riesgo</Col>
                                <Col sm='3'>Tiempo de desarrollo de PIPC</Col>
                            </Row>
                        </ListGroup.Item>
                        {
                            estadisticaMes.pipcRiesgoBajo?.map((s: Servicio, index: number) => {
                                return <ListGroup.Item key={`${index}`}>
                                    <Row>
                                        <Col sm='6' className="fw-bold">
                                            {s.folio}
                                        </Col>
                                        <Col>
                                            Bajo
                                        </Col>
                                        <Col sm='3' className="fw-bold">
                                            {s.primeraEtapaOperativa} días
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            })
                        }
                        {
                            estadisticaMes.pipcRiesgoMedio?.map((s: Servicio, index: number) => {
                                return <ListGroup.Item key={`${index}`}>
                                    <Row>
                                        <Col sm='6' className="fw-bold">
                                            {s.folio}
                                        </Col>
                                        <Col>
                                            Medio
                                        </Col>
                                        <Col sm='3' className="fw-bold">
                                            {s.primeraEtapaOperativa} días
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            })
                        }
                    </ListGroup>
                </div>
            </Tab>
            <Tab eventKey='desarrolloPIPC' title='Desarrollo de PIPC'>
                <div style={{marginTop: '20px', borderBottom: '1px solid #c1c1c1'}}>
                    <Row>
                        <Col>
                            <IndicadorItem titulo={'PIPC en desarrollo del mes'}
                                           valor={`${estadisticaMes.pipcEnDesarrollo.length}`}/>
                        </Col>
                        <Col>
                            <IndicadorItem titulo={'PIPC ingresados en el mes'}
                                           valor={`${estadisticaMes.pipcIngresados.length}`}/>
                        </Col>
                        <Col>
                            <IndicadorItem titulo={'PIPC liberados en el mes'}
                                           valor={`${estadisticaMes.pipcLiberados.length}`}/>
                        </Col>
                        <Col>
                            <IndicadorItem titulo={'PIPC con observaciones'}
                                           valor={`${estadisticaMes.pipcObservaciones.length}`}/>
                        </Col>
                        <Col>
                            <IndicadorItem titulo={'PIPC atrasados del mes'}
                                           valor={`${estadisticaMes.pipcAtrasados.length}`}/>
                        </Col>
                    </Row>
                </div>

                <div style={{marginTop: '20px'}}>
                    <ListGroup>
                        <ListGroup.Item className='fw-bold' key={'headerDetalles'}
                                        style={{backgroundColor: '#3a3939', color: 'white'}}>
                            <Row>
                                <Col sm='6'>Folio</Col>
                                <Col>Status</Col>
                            </Row>
                        </ListGroup.Item>
                        {
                            estadisticaMes.pipcEnDesarrollo?.map((s: Servicio, index: number) => {
                                return <ListGroup.Item key={`${index}`}>
                                    <Row>
                                        <Col sm='6' className="fw-bold">
                                            {s.folio}
                                        </Col>
                                        <Col className="fw-bold">
                                            En desarrollo
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            })

                        }
                        {
                            estadisticaMes.pipcIngresados?.map((s: Servicio, index: number) => {
                                return <ListGroup.Item key={`${index}`}>
                                    <Row>
                                        <Col sm='6' className="fw-bold">
                                            {s.folio}
                                        </Col>
                                        <Col className="fw-bold">
                                            Ingresado
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            })

                        }
                        {
                            estadisticaMes.pipcLiberados?.map((s: Servicio, index: number) => {
                                return <ListGroup.Item key={`${index}`}>
                                    <Row>
                                        <Col sm='6' className="fw-bold">
                                            {s.folio}
                                        </Col>
                                        <Col className="fw-bold">
                                            Liberado
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            })

                        }
                        {
                            estadisticaMes.pipcObservaciones?.map((s: Servicio, index: number) => {
                                return <ListGroup.Item key={`${index}`}>
                                    <Row>
                                        <Col sm='6' className="fw-bold">
                                            {s.folio}
                                        </Col>
                                        <Col className="fw-bold">
                                            Con observaciones
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            })

                        }
                        {
                            estadisticaMes.pipcAtrasados?.map((s: Servicio, index: number) => {
                                return <ListGroup.Item key={`${index}`}>
                                    <Row>
                                        <Col sm='6' className="fw-bold">
                                            {s.folio}
                                        </Col>
                                        <Col className="fw-bold">
                                            Atrasado
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            })

                        }
                    </ListGroup>
                </div>

            </Tab>
            <Tab eventKey='evaluaciones' title='Evaluaciones de satisfacción'>
                <div style={{marginTop: '40px', borderBottom: '1px solid #c1c1c1'}}>
                    <Row>
                        <Col>
                            <IndicadorItem
                                titulo={'Evaluaciones con calificación superior al 70%'}
                                valor={`${porcentajeText((estadisticaMes.evaluacionesSatisfaccionSuperior70.length / estadisticaMes.evaluacionesSatisfaccionRealizadas.length) * 100.0)}`}/>
                        </Col>
                        <Col>
                            <IndicadorItem titulo={'Evaluaciones de satisfacción realizadas'}
                                           valor={`${estadisticaMes.evaluacionesSatisfaccionRealizadas.length}`}/>
                        </Col>
                        <Col>
                            <IndicadorItem titulo={'Evaluaciones de satisfacción pendientes'}
                                           valor={`${estadisticaMes.evaluacionesSatisfaccionPendientes.length}`}/>
                        </Col>
                        <Col>
                            <IndicadorItem titulo={'Evaluaciones con calificación superior al 95%'}
                                           valor={`${porcentajeText((estadisticaMes.evaluacionesSatisfaccionSuperior95.length / estadisticaMes.evaluacionesSatisfaccionRealizadas.length) * 100.0)}`}/>
                        </Col>
                    </Row>
                </div>

                <div style={{marginTop: '20px'}}>
                    <ListGroup>
                        <ListGroup.Item className='fw-bold' key={'headerDetalles'}
                                        style={{backgroundColor: '#3a3939', color: 'white'}}>
                            <Row>
                                <Col sm='6'>Folio</Col>
                                <Col sm='3'>Status</Col>
                                <Col>Calificación</Col>
                            </Row>
                        </ListGroup.Item>
                        {
                            estadisticaMes.evaluacionesSatisfaccionPendientes.map((servicio: Servicio) => {
                                return <ListGroup.Item key={`${servicio.id}`}>
                                    <Row>
                                        <Col sm='6'><label className='fw-bold'>{servicio.folio}</label></Col>
                                        <Col>Pendiente</Col>
                                        <Col></Col>
                                    </Row>
                                </ListGroup.Item>
                            })
                        }
                        {
                            estadisticaMes.evaluacionesSatisfaccionSuperior70.map((servicio: Servicio) => {
                                return <ListGroup.Item key={`${servicio.id}`}>
                                    <Row>
                                        <Col sm='6'><label className='fw-bold'
                                                           style={{fontSize: '0.8em'}}>{servicio.folio}</label></Col>
                                        <Col></Col>
                                        <Col>{servicio.encuestaSatisfaccion?.calificacion}</Col>
                                    </Row>
                                </ListGroup.Item>
                            })
                        }
                        {
                            estadisticaMes.evaluacionesSatisfaccionMenor70.map((servicio: Servicio) => {
                                return <ListGroup.Item key={`${servicio.id}`}>
                                    <Row>
                                        <Col sm='6'><label className='fw-bold'
                                                           style={{fontSize: '0.8em'}}>{servicio.folio}</label></Col>
                                        <Col></Col>
                                        <Col>{servicio.encuestaSatisfaccion?.calificacion}</Col>
                                    </Row>
                                </ListGroup.Item>
                            })
                        }
                    </ListGroup>
                </div>
            </Tab>
        </Tabs>
    )
}