import {Servicio} from "../../types/servicio";
import {Container, Form} from "react-bootstrap";
import React from "react";


export default function FormGeneralReadOnly(props:{servicio:Servicio}){
    return (
        <Container style={{marginTop: '20px', marginBottom: '20px'}}>
            <Form className="row g-6" id="formGeneral">
                <Form.Group className="col-md-6" controlId="formGeneral"
                            style={{marginBottom: "20px"}}>
                    <Form.Label>Correo del Contacto</Form.Label>
                    <div className='not-available rounded'><center>Se requiere iniciar sesión para ver este valor</center></div>
                </Form.Group>
                <Form.Group className="col-md-6" controlId="formGeneral"
                            style={{marginBottom: "20px"}}>
                    <Form.Label>Nombre del Contacto</Form.Label>
                    <Form.Control type='text'className="formFont" value={props.servicio.cliente.nombreCompleto} disabled={true}/>
                </Form.Group>

                <Form.Group className="col-md-6" controlId="formGeneral"
                            style={{marginBottom: "20px"}}>
                    <Form.Label>Teléfono del Contacto</Form.Label>
                    <div className='not-available rounded'><center>Se requiere iniciar sesión para ver este valor</center></div>
                </Form.Group>
                <Form.Group className="col-md-6" controlId="formGeneral"
                            style={{marginBottom: "20px"}}>
                    <Form.Label>Folio</Form.Label>
                    <Form.Control type="text" className="formFont" value={props.servicio.folio} disabled={true}/>
                </Form.Group>

                <Form.Group className="col-md-6" controlId="formGeneral"
                            style={{marginBottom: "20px"}}>
                    <Form.Label>Nombre Comercial</Form.Label>
                    <div className='not-available rounded'><center>Se requiere iniciar sesión para ver este valor</center></div>
                </Form.Group>
                <Form.Group className="col-md-6" controlId="formGeneral"
                            style={{marginBottom: "20px"}}>
                    <Form.Label>Dirección Exacta del Inmueble</Form.Label>
                    <div className='not-available rounded'><center>Se requiere iniciar sesión para ver este valor</center></div>
                </Form.Group>
                <Form.Group className="col-md-6" controlId="formGeneral"
                            style={{marginBottom: "20px"}}>
                    <Form.Label>Giro Comercial</Form.Label>
                    <div className='not-available rounded'><center>Se requiere iniciar sesión para ver este valor</center></div>
                </Form.Group>
                <Form.Group className="col-md-6" controlId="formGeneral"
                            style={{marginBottom: "20px"}}>
                    <Form.Label>Metros cuadrados de Construcción</Form.Label>
                    <div className='not-available rounded'><center>Se requiere iniciar sesión para ver este valor</center></div>
                </Form.Group>
                <Form.Group className="col-md-6" controlId="formGeneral"
                            style={{marginBottom: "20px"}}>
                    <Form.Label>Metros cuadrados de Terreno</Form.Label>
                    <div className='not-available rounded'><center>Se requiere iniciar sesión para ver este valor</center></div>
                </Form.Group>
                <Form.Group className="col-md-6" controlId="formGeneral"
                            style={{marginBottom: "20px"}}>
                    <Form.Label>No. de empleados</Form.Label>
                    <div className='not-available rounded'><center>Se requiere iniciar sesión para ver este valor</center></div>
                </Form.Group>
                <Form.Group className="col-md-6" controlId="formGeneral"
                            style={{marginBottom: "20px"}}>
                    <Form.Label>Sustancias o Químicos Peligrosos</Form.Label>
                    <div className='not-available rounded'><center>Se requiere iniciar sesión para ver este valor</center></div>
                </Form.Group>
                <Form.Group className="col-md-6" controlId="formGeneral"
                            style={{marginBottom: "20px"}}>
                    <Form.Label>Gas, capacidad de almacenamiento</Form.Label>
                    <div className='not-available rounded'><center>Se requiere iniciar sesión para ver este valor</center></div>
                </Form.Group>
                <Form.Group className="col-md-6" controlId="formGeneral"
                            style={{marginBottom: "20px"}}>
                    <Form.Label>Razón Social</Form.Label>
                    <div className='not-available rounded'><center>Se requiere iniciar sesión para ver este valor</center></div>
                </Form.Group>
                <Form.Group className="col-md-6" controlId="formGeneral"
                            style={{marginBottom: "20px"}}>
                    <Form.Label>Domicilio Fiscal</Form.Label>
                    <div className='not-available rounded'><center>Se requiere iniciar sesión para ver este valor</center></div>
                </Form.Group>
                <Form.Group className="col-md-6" controlId="formGeneral"
                            style={{marginBottom: "20px"}}>
                    <Form.Label>RFC (Registro Federal de Contribuyente)</Form.Label>
                    <div className='not-available rounded'><center>Se requiere iniciar sesión para ver este valor</center></div>
                </Form.Group>


                <Form.Group className="col-md-6" controlId="formDatosGenerales"
                            style={{marginBottom: "20px"}}>
                    <Form.Label>Representante Legal del Inmueble</Form.Label>
                    <div className='not-available rounded'><center>Se requiere iniciar sesión para ver este valor</center></div>
                </Form.Group>
                <Form.Group className="col-md-6" controlId="formDatosGenerales"
                            style={{marginBottom: "20px"}}>
                    <Form.Label>Nombre del personal que recibirá al inspector</Form.Label>
                    <div className='not-available rounded'><center>Se requiere iniciar sesión para ver este valor</center></div>
                </Form.Group>
                <Form.Group className="col-md-6" controlId="formDatosGenerales"
                            style={{marginBottom: "20px"}}>
                    <Form.Label>Niveles de construcción</Form.Label>
                    <div className='not-available rounded'><center>Se requiere iniciar sesión para ver este valor</center></div>
                </Form.Group>
                <Form.Group className="col-md-6" controlId="formDatosGenerales"
                            style={{marginBottom: "20px"}}>
                    <Form.Label>Horario laboral</Form.Label>
                    <div className='not-available rounded'><center>Se requiere iniciar sesión para ver este valor</center></div>
                </Form.Group>
            </Form>
        </Container>

    )
}