import React from "react";
import {Navbar} from "react-bootstrap";
import {useNavigate} from "react-router-dom";


const PublicNavbar = () => {

    const navigate=useNavigate();

    return (
        <Navbar style={{background: "#FFFFFF", border: "1px solid lightgray"}}>
            <a className="navbar-brand" href="#" onClick={()=>{
                navigate('/');
            }}>
                <img src="/logo.jpg" alt="" width="220" height="100"/>
            </a>
        </Navbar>)
}

export default PublicNavbar;
