import React, {useCallback, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Container} from "react-bootstrap";
import {getPublicServiceDetails, getServiceDetails} from "../../services/conem-services";
import PublicNavbar from "./PublicNavbar";
import PanelServicio from "./PanelServicio";
import {Servicio} from "../../types/servicio";
import {getPorcentajeAvance, getUsuarioSesion, SUCCESS} from "../../utils";
import Navbar from "../common/Navbar";
import ServicioList from "../servicio/ServicioList";

export default function ServicioDetalle() {

    const {id} = useParams();
    const [error, setError] = useState<{ message: string } | null>(null);
    const [servicio, setServicio] = useState<Servicio | null>(null);

    const fetchData = useCallback(async (id: string) => {
        if (getUsuarioSesion()) {
            return getServiceDetails(id);
        } else {
            return getPublicServiceDetails(id);
        }
    }, []);

    const navigate = useNavigate();

    useEffect(() => {
        try {
            //@ts-ignore
            let real = atob(id);
            fetchData(real).then(resp => {
                if (resp.codigo === SUCCESS) {
                    setServicio(resp.object);
                    console.log(resp.object);
                } else {
                    setError({message: 'Oops!  al parecer ha ocurrido un error, por favor inténtelo más tarde'});
                }
            }).catch(error => {
                setError({message: 'Oops!  al parecer ha ocurrido un error, por favor inténtelo más tarde'});
            });
        } catch (e) {
            setError({message: 'Oops!  al parecer ha ocurrido un error, por favor inténtelo más tarde'});
        }
    }, []);

    return (<>
        {
            getUsuarioSesion() === null && <>
                <PublicNavbar></PublicNavbar>
                {
                    (error || servicio === null) ?
                        <Container style={{paddingTop: "100px"}}>
                            <center>
                                <h2>{error?.message}</h2>
                            </center>
                        </Container> :
                        <PanelServicio servicio={servicio} avance={getPorcentajeAvance(servicio)}/>
                }
            </>
        }
        {

            (getUsuarioSesion() !== null && servicio) && <>
                <Navbar/>
                <div className="d-flex flex-row workspace">
                    <ServicioList hideSidebar={() => navigate('/home')}
                                  showSidebar={() => navigate('/home')}
                                  selectedService={servicio}/>
                </div>
            </>
        }

    </>)
}