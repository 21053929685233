import React from "react";


export default function PageHeader(props: { label: string }) {
    return (
        <>
            <div style={{width: '100%', marginTop: "5px", marginBottom: '5px'}}>
                <span className="fs-3 fw-bold" style={{color: '#3a3939'}}>{props.label}</span>
            </div>
        </>
    )
}