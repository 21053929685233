import React from "react";
import {Col, Modal, Row} from "react-bootstrap";
import {getPerfilLabel, getUsuarioSesionNombreCompleto, getUsuarioSesionUsername} from "../../utils";

export default function PerfilPanel(props: { show: boolean, close: any }) {

    return <>
        <Modal size="lg" className="modal-dialog-centered"
               show={props.show} onHide={props.close}>
            <Modal.Header className='modal-header'>
                <Modal.Title className="fs-6">Perfil</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col sm='3'>
                        Nombre completo:
                    </Col>
                    <Col>
                        <span className='fw-bold'>{getUsuarioSesionNombreCompleto()}</span>
                    </Col>
                </Row>
                <Row>
                    <Col sm='3'>
                        Correo electrónico:
                    </Col>
                    <Col>
                        <span className='fw-bold'>{getUsuarioSesionUsername()}</span>
                    </Col>
                </Row>
                <Row>
                    <Col sm='3'>
                        Perfil:
                    </Col>
                    <Col>
                        <span className='fw-bold'>{getPerfilLabel()}</span>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    </>
}