import React, {useEffect, useState} from "react";
import {Dropdown} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {doLogout} from "../../services/conem-services";
import PerfilPanel from "../login/PerfilPanel";
import {Notificacion} from "../../types/servicio";
import {getUsuarioSesion, isRequeridoCambioContraseña} from "../../utils";

const Navbar = () => {

    const navigate = useNavigate();


    const [authenticated, setAuthenticated] = useState(false);

    const [notificacionesPendientes, setNotificacionesPendientes] = useState<number>(0);
    const [realNotificaciones, setRealNotificaciones] = useState<Notificacion[]>([]);

    useEffect(() => {
        const loggedInUser = getUsuarioSesion();
        if (loggedInUser) {
            setAuthenticated(true);
            const sseNotificaciones = new EventSource(
                `${process.env.REACT_APP_SERVER_URL}/api/notificaciones/usuarios/${getUsuarioSesion().id}/events`,
                {
                    withCredentials: true,
                }
            );
            sseNotificaciones.onopen = (e) => {
                console.log("SSE connected!");
            };
            sseNotificaciones.addEventListener("all", (event) => {
                try {
                    setNotificacionesPendientes(event.data);
                } catch (err) {
                }
            });
            sseNotificaciones.onerror = (error) => {
                console.log("SSE For All Error", error);
                sseNotificaciones.close();
            };
            return () => {
                sseNotificaciones.close();
            };
        } else {
            navigate('/')
        }

    }, [notificacionesPendientes]);

    async function logout() {
        await doLogout();
        navigate('/')
    }

    const [showProfilePanel, setShowProfilePanel] = useState<boolean>(false);
    const openProfilePanel = () => setShowProfilePanel(true);

    const closeProfilePanel = () => setShowProfilePanel(false);

    return (<nav className="navbar navbar-light"
                 style={{background: "white", border: "1px solid lightgray"}}>
        <a className="navbar-brand" href="#" onClick={()=>navigate('/home')} style={{marginTop: '0px', paddingTop: '0px'}}>
            <img src="/logo.jpg" alt="" width="220" height="100">
            </img>
        </a>
        {(authenticated && !isRequeridoCambioContraseña()) &&
            <div className="d-flex align-items-center" style={{marginRight: "30px"}}>

                <Dropdown>
                    <Dropdown.Toggle variant="light" className="text-reset me-3 dropdown-toggle"
                                     href="#"
                                     id="navbarDropdownMenuAvatar"
                                     role="button"
                                     data-toggle="dropdown"
                                     aria-expanded="false">
                        <span className="bi-person-circle fw-bold"/>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item href="#" onClick={openProfilePanel}>Mi perfil</Dropdown.Item>
                        <Dropdown.Item href="#" onClick={logout}>Cerrar sesión</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        }

        <PerfilPanel show={showProfilePanel} close={closeProfilePanel}/>

    </nav>)
}

export default Navbar;
