import React from "react";

export default function Loader(props: { show: boolean }) {
    return (
        <>
            {
                props.show &&
                <div id="backdrop">
                    <div className="text-center loading">
                        <div className="spinner-border" role="status">
                            <span className="sr-only"/>
                        </div>
                    </div>
                </div>
            }
        </>

    )
}