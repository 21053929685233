import {Button, Container, Form} from "react-bootstrap";
import {
    dateToSimpleFormat,
    isAdmin,
    isCliente,
    isCoordinador,
    isCorreoValido,
    isTelefonoValido,
    isVentas,
    SUCCESS,
    toDate
} from "../../utils";
import {actualizarDatosGeneralesServicio} from "../../services/conem-services";
import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import {useFormInput} from "../cotizacion/useFormInput";
import {Archivo, Servicio, WorkingDay} from "../../types/servicio";
import DiasSemana from "../cotizacion/DiasSemana";
import FileChooser from "../documento/FileChooser";
import ModalMessage from "../common/ModalMessage";
import Loader from "../common/Loader";


export default function FormGeneralEditable(props: {
    servicio: Servicio,
    updateService?: (service: Servicio) => void
}) {

    const [message, setMessage] = useState<string>('');
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [servicio, setServicio] = useState<Servicio>(props.servicio);

    useEffect(() => {
        setServicio(props.servicio);
        if (props.servicio.cuentaVistoBuenoPCAnteriores) {
            setVistoBuenoAniosAnteriores(props.servicio.cuentaVistoBuenoPCAnteriores);
        } else {
            setVistoBuenoAniosAnteriores(false);
        }
        if (props.servicio.vigenciaVistoBueno) {
            setVigenciaVistoBueno(toDate(props.servicio.vigenciaVistoBueno))
        } else {
            setVigenciaVistoBueno(null);
        }
        if (props.servicio.diasLaborales) {
            setDiasLaborales(props.servicio.diasLaborales)
        } else {
            setDiasLaborales([
                {
                    name: 'Domingo', enabled: false
                },
                {
                    name: 'Lunes', enabled: true
                },
                {
                    name: 'Martes', enabled: true
                },
                {
                    name: 'Miércoles', enabled: true
                },
                {
                    name: 'Jueves', enabled: true
                },
                {
                    name: 'Viernes', enabled: true
                },
                {
                    name: 'Sábado', enabled: false
                },
            ]);
        }

        setRequiereFactura(props.servicio.requiereFactura)
        if (props.servicio.constanciaSituacionFiscal) {
            setConstanciaSituacionFiscal(props.servicio.constanciaSituacionFiscal)
        } else {
            setConstanciaSituacionFiscal(null);
        }
    }, [props.servicio]);

    const showErrorMessage = (message: string) => {
        setError(true);
        setMessage(message);
    }

    const isFormEditable = () => {
        return ((isCliente() || isVentas() || isAdmin() || isCoordinador()) && (props.servicio.status === 'por_iniciar'));
    }

    function isFormValid() {
        let valid = true;
        valid = (
            telefonoContacto.error === null &&
            direccionExacta.error === null &&
            metrosConstruccion.error === null &&
            metrosTerreno.error === null &&
            numeroEmpleados.error === null &&
            razonSocial.error === null &&
            domicilioFiscal.error === null &&
            rfc.error === null &&
            representanteLegalInmueble.error === null &&
            nombrePersonalRecibiraInspector.error === null &&
            nivelesConstruccion.error === null &&
            horarioLaboral.error === null
        )
        return valid;
    }

    const createUpdatedService = () => {
        const updatedService = {...props.servicio}
        updatedService.cliente.telefono = telefonoContacto.value;
        updatedService.cliente.detalle.direccion = direccionExacta.value;
        updatedService.cliente.detalle.metrosConstruccion = metrosConstruccion.value;
        updatedService.cliente.detalle.metrosTerreno = metrosTerreno.value;
        updatedService.cliente.detalle.numeroEmpleados = numeroEmpleados.value;
        updatedService.cliente.detalle.razonSocial = razonSocial.value;
        updatedService.cliente.detalle.domicilioFiscal = domicilioFiscal.value;
        updatedService.cliente.detalle.rfc = rfc.value;
        updatedService.representanteLegalInmueble = representanteLegalInmueble.value;
        updatedService.nombrePersonalRecibeInspector = nombrePersonalRecibiraInspector.value;
        updatedService.nivelesConstruccion = nivelesConstruccion.value;
        updatedService.horarioLaboral = horarioLaboral.value;
        updatedService.cuentaVistoBuenoPCAnteriores = vistoBuenoAniosAnteriores;
        updatedService.vigenciaVistoBueno = vigenciaVistoBueno?dateToSimpleFormat(vigenciaVistoBueno):null;
        updatedService.diasLaborales = diasLaborales;
        updatedService.requiereFactura = requiereFactura;
        updatedService.constanciaSituacionFiscal = constanciaSituacionFiscal;
        return updatedService;
    }


    const actualizarDatosGenerales = () => {
        if (isFormValid() && servicio.id) {
            const updatedService = createUpdatedService();
            setLoading(true);
            actualizarDatosGeneralesServicio(servicio.id, updatedService).then(resp => {
                if (resp.codigo === SUCCESS) {
                    if (props.updateService) {
                        props.updateService(resp.object);
                    }
                } else {
                    showErrorMessage(resp.mensaje);
                }
            }).catch(err => {
                showErrorMessage("Lo sentimos ocurrió un error al intentar actualizar los datos generales, por favor inténtelo más tarde");
            }).finally(() => {
                setLoading(false);
            })
        } else {
            showErrorMessage('Por favor verifique y proporcione los campos requeridos');
        }

    }

    const [constanciaSituacionFiscal, setConstanciaSituacionFiscal] = useState<Archivo | null>(null);
    const [vistoBuenoAniosAnteriores, setVistoBuenoAniosAnteriores] = useState<boolean>(false);
    const [vigenciaVistoBueno, setVigenciaVistoBueno] = useState<Date | null>(null);
    const [diasLaborales, setDiasLaborales] = useState<WorkingDay[]>([
        {
            name: 'Domingo', enabled: false
        },
        {
            name: 'Lunes', enabled: true
        },
        {
            name: 'Martes', enabled: true
        },
        {
            name: 'Miércoles', enabled: true
        },
        {
            name: 'Jueves', enabled: true
        },
        {
            name: 'Viernes', enabled: true
        },
        {
            name: 'Sábado', enabled: false
        },
    ]);
    const changeSelectionDay = (index: number, value: boolean) => {
        setDiasLaborales(
            diasLaborales.map((day: WorkingDay, i: number) => {
                if (i === index) {
                    day.enabled = value;
                }
                return day;
            })
        )
    }

    const [requiereFactura, setRequiereFactura] = useState<boolean>(props.servicio.requiereFactura);

    useEffect(() => {
        if (props.servicio.constanciaSituacionFiscal) {
            setConstanciaSituacionFiscal(props.servicio.constanciaSituacionFiscal)
        } else {
            setConstanciaSituacionFiscal(null);
        }
        if (props.servicio.vigenciaVistoBueno) {
            setVigenciaVistoBueno(toDate(props.servicio.vigenciaVistoBueno))
        } else {
            setVigenciaVistoBueno(null)
        }
        if (props.servicio.cuentaVistoBuenoPCAnteriores) {
            setVistoBuenoAniosAnteriores(props.servicio.cuentaVistoBuenoPCAnteriores)
        }
        else{
            setVistoBuenoAniosAnteriores(false)
        }
        if (props.servicio.diasLaborales) {
            setDiasLaborales(props.servicio.diasLaborales)
        } else {
            setDiasLaborales([
                {
                    name: 'Domingo', enabled: false
                },
                {
                    name: 'Lunes', enabled: true
                },
                {
                    name: 'Martes', enabled: true
                },
                {
                    name: 'Miércoles', enabled: true
                },
                {
                    name: 'Jueves', enabled: true
                },
                {
                    name: 'Viernes', enabled: true
                },
                {
                    name: 'Sábado', enabled: false
                },
            ])
        }
        setRequiereFactura(props.servicio.requiereFactura)

    }, [props.servicio]);


    const correo = useFormInput(
        props.servicio.cliente.correo,
        100,
        'El correo de contacto es requerido',
        false,
        true,
        true,
        isCorreoValido,
    );

    const nombreContacto = useFormInput(
        props.servicio.cliente.nombreCompleto,
        200,
        'El nombre del contacto es requerido',
        false,
        true,
        true
    );
    const telefonoContacto = useFormInput(
        props.servicio.cliente.telefono,
        10,
        'El número de teléfono de contacto es requerido',
        false,
        true,
        !isFormEditable(),
        isTelefonoValido,
    );
    const verificarFolio = (value: string) => {
        if (!value.match(/[A-Z]{3}20[0-9]{2}-SPC-[A-Z]+/)) {
            return false;
        }
        return true;
    }
    const folio = useFormInput(
        props.servicio.folio,
        100,
        'El número de folio es requerido y debe ser válido',
        false,
        true,
        true,
        verificarFolio,
    );
    const nombreComercial = useFormInput(
        props.servicio.cliente.detalle.nombreComercial,
        200,
        'El nombre comercial es un campo requerido',
        false,
        true,
        true,
    );
    const direccionExacta = useFormInput(
        props.servicio.cliente.detalle.direccion,
        400,
        'La dirección exacta es un campo requerido',
        false,
        true,
        !isFormEditable(),
    );
    const giroComercial = useFormInput(
        props.servicio.cliente.detalle.giro,
        400,
        'El campo Giro Comercial es requerido',
        false,
        true,
        true,
    );
    const metrosConstruccion = useFormInput(
        props.servicio.cliente.detalle.metrosConstruccion,
        100,
        'El campo Metros de Construcción es requerido',
        false,
        true,
        !isFormEditable(),
    );
    const metrosTerreno = useFormInput(
        props.servicio.cliente.detalle.metrosTerreno,
        100,
        null,
        true,
        false,
        !isFormEditable(),
    );
    const numeroEmpleados = useFormInput(
        props.servicio.cliente.detalle.numeroEmpleados,
        100,
        null,
        true,
        false,
        !isFormEditable(),
    );
    const sustanciasQuimicosPeligrosos = useFormInput(
        props.servicio.cliente.detalle.sustanciasQuimicasPeligrosas,
        300,
        'Indique NA en caso de no aplicar',
        false,
        true,
        true,
    );
    const gasCapacidadAlmacenamiento = useFormInput(
        props.servicio.cliente.detalle.gasCapacidadAlmacenamiento,
        300,
        'Indique NA en caso de no aplicar',
        false,
        true,
        true,
    );
    const razonSocial = useFormInput(
        props.servicio.cliente.detalle.razonSocial,
        100,
        'La Razón Social es un campo requerido',
        false,
        true,
        !isFormEditable(),
    );
    const domicilioFiscal = useFormInput(
        props.servicio.cliente.detalle.domicilioFiscal,
        400,
        null,
        true,
        false,
        !isFormEditable(),
    );
    const rfc = useFormInput(
        props.servicio.cliente.detalle.rfc,
        30,
        null,
        true,
        false,
        !isFormEditable(),
    );

    const representanteLegalInmueble = useFormInput(
        props.servicio.representanteLegalInmueble,
        200,
        null,
        true,
        false,
        !isFormEditable(),
    );
    const nombrePersonalRecibiraInspector = useFormInput(
        props.servicio.nombrePersonalRecibeInspector,
        200,
        null,
        true,
        false,
        !isFormEditable(),
    );
    const nivelesConstruccion = useFormInput(
        props.servicio.nivelesConstruccion,
        50,
        null,
        true,
        false,
        !isFormEditable(),
    );
    const horarioLaboral = useFormInput(
        props.servicio.horarioLaboral,
        50,
        null,
        true,
        false,
        !isFormEditable(),
    );


    return (
        <>
            <Loader show={loading}/>
            <Container style={{marginTop: '20px', marginBottom: '20px'}}>
                <Form className="row g-6" id="formGeneral">
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Correo del Contacto</Form.Label>
                        <Form.Text className="text-muted"> (Requerido)</Form.Text>
                        <Form.Control type="email" className="formFont" {...correo}/>
                        <Form.Control.Feedback type="invalid">{correo.error}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Nombre del Contacto</Form.Label>
                        <Form.Text className="text-muted"> (Requerido)</Form.Text>
                        <Form.Control {...nombreContacto}/>
                        <Form.Control.Feedback type="invalid">{nombreContacto.error}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Teléfono del Contacto</Form.Label>
                        <Form.Text className="text-muted"> (Requerido)</Form.Text>
                        <Form.Control type="text" className="formFont" {...telefonoContacto}/>
                        <Form.Control.Feedback
                            type="invalid">{telefonoContacto.error}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Folio</Form.Label>
                        <Form.Text className="text-muted"> (Requerido)</Form.Text>
                        <Form.Control type="text" className="formFont" {...folio}/>
                        <Form.Control.Feedback
                            type="invalid">{folio.error}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Nombre Comercial</Form.Label>
                        <Form.Text className="text-muted"> (Requerido)</Form.Text>
                        <Form.Control type="text" className="formFont" {...nombreComercial}></Form.Control>
                        <Form.Control.Feedback
                            type="invalid">{nombreComercial.error}</Form.Control.Feedback>

                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Dirección Exacta del Inmueble</Form.Label>
                        <Form.Text className="text-muted"> (Requerido)</Form.Text>
                        <Form.Control type="text" className="formFont" {...direccionExacta}/>
                        <Form.Control.Feedback
                            type="invalid">{direccionExacta.error}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Giro Comercial</Form.Label>
                        <Form.Control type="text" className="formFont" {...giroComercial}/>
                        <Form.Control.Feedback
                            type="invalid">{giroComercial.error}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Metros cuadrados de Construcción</Form.Label>
                        <Form.Text className="text-muted"> (Requerido)</Form.Text>
                        <Form.Control type="text" className="formFont" {...metrosConstruccion}/>
                        <Form.Control.Feedback
                            type="invalid">{metrosConstruccion.error}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Metros cuadrados de Terreno</Form.Label>
                        <Form.Control type="text" className="formFont" {...metrosTerreno}/>
                        <Form.Control.Feedback
                            type="invalid">{metrosTerreno.error}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>No. de empleados</Form.Label>
                        <Form.Control type="text" className="formFont" {...numeroEmpleados}/>
                        <Form.Control.Feedback
                            type="invalid">{numeroEmpleados.error}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Sustancias o Químicos Peligrosos</Form.Label>
                        <Form.Text className="text-muted"> (Requerido)</Form.Text>
                        <Form.Control type="text" className="formFont" {...sustanciasQuimicosPeligrosos}/>
                        <Form.Control.Feedback
                            type="invalid">{sustanciasQuimicosPeligrosos.error}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Gas, capacidad de almacenamiento</Form.Label>
                        <Form.Text className="text-muted"> (Requerido)</Form.Text>
                        <Form.Control type="text" className="formFont" {...gasCapacidadAlmacenamiento}/>
                        <Form.Control.Feedback
                            type="invalid">{gasCapacidadAlmacenamiento.error}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Razón Social</Form.Label>
                        <Form.Text className="text-muted"> (Se requiere el nombre de la empresa ante
                            el
                            SAT)</Form.Text>
                        <Form.Control type="text" className="formFont" {...razonSocial}/>
                        <Form.Control.Feedback
                            type="invalid">{razonSocial.error}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Domicilio Fiscal</Form.Label>
                        <Form.Control type="text" className="formFont" {...domicilioFiscal}/>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>RFC (Registro Federal de Contribuyente)</Form.Label>
                        <Form.Control type="text" className="formFont" {...rfc}/>
                    </Form.Group>


                    <Form.Group className="col-md-6" controlId="formDatosGenerales"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Representante Legal del Inmueble</Form.Label>
                        <Form.Control type="text" className="formFont" {...representanteLegalInmueble}/>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formDatosGenerales"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Nombre del personal que recibirá al inspector</Form.Label>
                        <Form.Control type="text" className="formFont" {...nombrePersonalRecibiraInspector}/>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formDatosGenerales"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Niveles de construcción</Form.Label>
                        <Form.Control type="text" className="formFont" {...nivelesConstruccion}/>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formDatosGenerales"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Horario laboral</Form.Label>
                        <Form.Control type="text" className="formFont" {...horarioLaboral}/>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formDatosGenerales"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>¿Cuentan con visto bueno de protección civil de años
                            anteriores?</Form.Label>
                        <div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox"
                                       id='requiereFactura'
                                       disabled={!isFormEditable()}
                                       checked={vistoBuenoAniosAnteriores}
                                       onChange={(e) => setVistoBuenoAniosAnteriores(e.target.checked)}/>
                                <label className="form-check-label" htmlFor="requiereFactura">
                                    Sí
                                </label>
                            </div>
                            {
                                vistoBuenoAniosAnteriores &&
                                <div>
                                    Vigencia:
                                    <DatePicker isClearable={isFormEditable()}
                                                dateFormat='dd/MM/yyyy' locale="es" disabled={!isFormEditable()}
                                                selected={vigenciaVistoBueno}
                                                onChange={(date, event) => {
                                                    setVigenciaVistoBueno(date);
                                                }}/>
                                </div>
                            }
                        </div>

                    </Form.Group>


                    <Form.Group className="col-md-6" controlId="formDatosGenerales"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Días laborales</Form.Label>
                        <div>
                            <DiasSemana workingDays={diasLaborales} disabled={!isFormEditable()}
                                        change={changeSelectionDay}/>
                        </div>
                    </Form.Group>

                </Form>

                <hr/>
                <div style={{marginTop: "10px"}}>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox"
                               id='requiereFactura'
                               disabled={!isFormEditable()}
                               checked={requiereFactura}
                               onChange={(e) => setRequiereFactura(e.target.checked)}/>
                        <label className="form-check-label" htmlFor="requiereFactura">
                            ¿Requiere factura?
                        </label>
                        {
                            (requiereFactura || constanciaSituacionFiscal) &&
                            <FileChooser archivo={constanciaSituacionFiscal} disabled={!isFormEditable()}
                                         buttonUploadLabel={`${constanciaSituacionFiscal!==null ? 'Agregar' : 'Actualizar'} constancia de situación fiscal`}
                                         updatedFile={(e:Archivo)=>setConstanciaSituacionFiscal(e)}/>
                        }
                    </div>
                </div>

                <Container className="d-flex flex-row justify-content-end"
                           style={{marginTop: "40px"}}>
                    <Button style={{borderRadius: "8px", padding: "10px", width: "260px"}}
                            variant="dark"
                            size='sm'
                            hidden={!isFormEditable()}
                            disabled={!isFormEditable()}
                            onClick={actualizarDatosGenerales}>
                        Actualizar información
                    </Button>
                </Container>
            </Container>

            <ModalMessage show={error} close={() => setError(false)} error={error} message={message}/>
        </>


    )
}